import MenuIcon from '@mui/icons-material/Menu';
import { Divider, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { ExcludedLinksInLists, navItems, Paths } from 'pages/Routes';
import * as React from 'react';
import { FullLogo } from './FullLogo';

export const LeftDrawer = (style?: React.CSSProperties | undefined) => {
    const textColor = useTheme().palette.text.primary;
    const appointmentNavItem = navItems.find((item) => item.path === Paths.appointmentFullUrl);

    const [isOpen, setIsOpen] = React.useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }

        setIsOpen(open);
    };

    const list = () => (
        <Box
            sx={{ width: 220 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem
                    key="logo"
                    style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                >
                    <FullLogo />
                </ListItem>
                <Divider />
                {navItems
                    .filter((item) => !ExcludedLinksInLists.includes(item.path))
                    .map((item) => (
                        <ListItem
                            key={item.title}
                            disablePadding
                        >
                            <Button
                                key={item.title}
                                sx={{ color: textColor, justifyContent: 'flex-start', width: '100%' }}
                                href={`/${item.path}`}
                            >
                                {item.title}
                            </Button>
                        </ListItem>
                    ))}
                <Divider />
                <ListItem
                    key={appointmentNavItem?.title}
                    disablePadding
                >
                    <Button
                        key={appointmentNavItem?.title}
                        sx={{ color: textColor, justifyContent: 'flex-start', width: '100%' }}
                        href={`${appointmentNavItem?.path}`}
                    >
                        {appointmentNavItem?.title}
                    </Button>
                </ListItem>
            </List>
        </Box>
    );
    return (
        <Box sx={{ ...style, marginRight: 4 }}>
            <Button
                variant="outlined"
                onClick={toggleDrawer(true)}
                color="inherit"
                sx={{ mr: -2 }}
                aria-label="Menu"
            >
                <MenuIcon />
            </Button>
            <SwipeableDrawer
                anchor={'left'}
                open={isOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {list()}
            </SwipeableDrawer>
        </Box>
    );
};
