import { Box, Typography } from '@mui/material';
import { createBrowserRouter } from 'react-router-dom';
import ConfusedDog from '../assets/confused-dog.webp';
import { About } from './About';
import { ContactUs } from './ContactUs';
import { FAQS } from './FAQS';
import { Home } from './Home';
import { Memorials } from './Memorials';
import { Pricing } from './Pricing';
import { Privacy } from './Privacy';
import { Resources } from './Resources';
import { Services } from './Services';
import { TermsAndConditions } from './TermsAndConditions';
import { Testimonials } from './Testimonials';
import { Time } from './Time';

export const baseUrl = 'https://passingpawsihe.com/';

// Update sitemap before altering these
export enum Paths {
    about = 'about',
    memorials = 'memorials',
    testimonials = 'testimonials',
    time = 'how-do-i-know-its-time',
    services = 'services',
    pricing = 'pricing',
    resources = 'resources',
    faqs = 'faqs',
    contact = 'contact',
    //appointment = 'appointment',
    appointmentFullUrl = 'https://form.jotform.com/233013525423141',
    privacy = 'privacy',
    terms = 'terms',
}

export const navItems = [
    { title: 'About Me', element: <About />, path: Paths.about },
    { title: 'Pet Memorials', element: <Memorials />, path: Paths.memorials },
    { title: 'Testimonials', element: <Testimonials />, path: Paths.testimonials },
    { title: "How Do I Know It's Time?", element: <Time />, path: Paths.time },
    { title: 'Services', element: <Services />, path: Paths.services },
    { title: 'Pricing', element: <Pricing />, path: Paths.pricing },
    { title: 'Resources', element: <Resources />, path: Paths.resources },
    { title: 'FAQS', element: <FAQS />, path: Paths.faqs },
    { title: 'Contact', element: <ContactUs />, path: Paths.contact },
    { title: 'Privacy', element: <Privacy />, path: Paths.privacy },
    { title: 'Terms And Conditions', element: <TermsAndConditions />, path: Paths.terms },
    { title: 'Appointment', element: <ContactUs />, path: Paths.appointmentFullUrl },
];

export const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <Home />,
        },
    ]
        .concat(navItems.filter((ni) => ni.title !== 'Appointment').map((item) => ({ path: item.path, element: item.element })))
        .concat([
            {
                path: '/*',
                element: (
                    <Box sx={{ margin: 10 }}>
                        <Typography variant="h4">Uh oh! Looks like you got lost. Try going back or using one of the links on this page.</Typography>
                        <img
                            src={ConfusedDog}
                            alt="Confused dog."
                            height={400}
                        />
                    </Box>
                ),
            },
        ]),
);

export const ExcludedLinksInLists = [Paths.appointmentFullUrl, Paths.privacy, Paths.terms];
