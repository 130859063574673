import { Button, Card, CardMedia, Typography } from '@mui/material';
import { navItems } from 'pages/Routes';

interface ContainedBannerButtonProps {
    path: string;
    image: string;
    alt?: string;
}

export const ContainedBannerButton = (props: ContainedBannerButtonProps) => {
    return (
        <Button
            href={props?.path}
            sx={{ marginBottom: 2, textTransform: 'none', marginLeft: { xs: 0, lg: 2 }, marginRight: { xs: 0, lg: 2 }, width: 280 }}
        >
            <Card sx={{}}>
                <CardMedia
                    style={{ width: '280px', height: '180px' }}
                    image={props?.image}
                    title={navItems.find((ni) => ni.path === props?.path)?.title}
                    aria-label={props.alt ?? undefined}
                >
                    <Typography
                        sx={{
                            width: 260,
                            position: 'relative',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textShadow: '2px 2px 0px #000000',
                            textAlign: 'center',
                            typography: {
                                lg: { fontSize: 40, fontFamily: 'Raleway Variable' }, // 1200
                                md: { fontSize: 40, fontFamily: 'Raleway Variable' }, // 900
                                sm: { fontSize: 40, fontFamily: 'Raleway Variable' }, // 600
                                mobile: { fontSize: 40, fontFamily: 'Raleway Variable' }, // 320
                                xs: { fontSize: 40, fontFamily: 'Raleway Variable' }, // 0
                            },
                        }}
                        color="white"
                    >
                        {navItems.find((ni) => ni.path === props?.path)?.title}
                    </Typography>
                </CardMedia>
            </Card>
        </Button>
    );
};
