import { Box, Button, Skeleton } from '@mui/material';
import { useState } from 'react';
import logo from '../assets/Passing-logo-cropped.webp';
export const FullLogo = () => {
    const [imageLoaded, setImageLoadedState] = useState(false);
    return (
        <Button
            href="/"
            style={{}}
        >
            <img
                height={140}
                alt={'Passing Paws In-Home Euthanasia Logo'}
                title={'Passing Paws In-Home Euthanasia Logo'}
                src={logo}
                style={{ aspectRatio: '2935 / 2202' }}
                onLoad={() => setImageLoadedState(true)}
            />

            {!imageLoaded && (
                <Box
                    height={140}
                    sx={{ aspectRatio: '2935 / 2202', paddingTop: 2, position: 'fixed' }}
                >
                    <Skeleton
                        variant="rectangular"
                        height={65}
                        animation="wave"
                    />
                    <Skeleton
                        variant="text"
                        height={40}
                        animation="wave"
                    />
                </Box>
            )}
        </Button>
    );
};
