import { Button } from '@mui/material';
import { Paths, navItems } from 'pages/Routes';

export const AppointmentButton = (style?: React.CSSProperties | undefined) => {
    const appointmentNavItem = navItems.find((item) => item.path === Paths.appointmentFullUrl);
    return (
        <Button
            key={appointmentNavItem?.title}
            href={`${appointmentNavItem?.path}`}
            color="secondary"
            variant="contained"
            style={{ ...style }}
        >
            {appointmentNavItem?.title}
        </Button>
    );
};
