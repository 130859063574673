import { Box, Skeleton, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';

interface PageBannerProps {
    bannerURL: string;
    title?: string;
    content?: ReactNode;
    alt?: string;
}

export const PageBanner = (props: PageBannerProps) => {
    const [imageLoaded, setImageLoadedState] = useState(false);
    return (
        <Box
            sx={{
                width: {
                    lg: '100%', // 1200
                    md: '115%', // 900
                    sm: '130%', // 600
                    mobile: '160%', // 320
                    xs: '180%', // 0
                },
                alignSelf: 'center',
                flexDirection: 'row',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    width: '60%',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                {props.content ? (
                    props.content
                ) : (
                    <Typography
                        variant="h1"
                        sx={{
                            typography: {
                                lg: { fontSize: 110, fontFamily: 'Raleway Variable' }, // 1200
                                md: { fontSize: 84, fontFamily: 'Raleway Variable' }, // 900
                                sm: { fontSize: 56, fontFamily: 'Raleway Variable' }, // 600
                                mobile: { fontSize: 44, fontFamily: 'Raleway Variable' }, // 320
                                xs: { fontSize: 30, fontFamily: 'Raleway Variable' }, // 0
                            },
                            textShadow: '2px 2px 0px #000000',
                            textAlign: 'center',
                        }}
                        color={'white'}
                        component="h1"
                    >
                        {props.title}
                    </Typography>
                )}
            </Box>

            <img
                src={props.bannerURL}
                width={'100%'}
                height={'auto'}
                alt={props.alt ?? ''}
                style={{ alignSelf: 'stretch', aspectRatio: '48 / 17', display: imageLoaded ? 'block' : 'none' }}
                onLoad={() => setImageLoadedState(true)}
            />

            {!imageLoaded && (
                <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={'auto'}
                    animation="wave"
                    sx={{ alignSelf: 'stretch', aspectRatio: '48 / 17', bgcolor: 'grey.500' }}
                />
            )}
        </Box>
    );
};
