import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { Footer } from 'components/Footer';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import TopBar from './components/TopBar';
import { router } from './pages/Routes';

export const websiteName = 'Passing Paws In-Home Pet Euthanasia';
export const legalName = 'Passing Paws In-Home Pet Euthanasia, LLC.';
export const websiteNameShort = 'Passing Paws';
// Test change
export const App = () => {
    const theme = createTheme({
        typography: {
            fontFamily: ['Poppins'].join(','),
        },
        breakpoints: {
            values: {
                xs: 0,
                mobile: 320,
                sm: 600,
                twoColumns: 700,
                md: 900,
                threeColumns: 1050,
                topBar: 1400,
                lg: 1200,
                xl: 1536,
            },
        },
        palette: {
            primary: {
                main: '#fbfbf9',
            },
            background: { default: '#f4f8f8', paper: '#f4f8f8' },
            secondary: {
                main: '#114241',
                contrastText: '#FFFFFF',
            },
            text: { primary: '#617070', secondary: '#7fb7b7' },
        },
    });

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        flex: 1,
                        overflowX: 'hidden',
                    }}
                >
                    <TopBar />
                    <Box sx={{ marginBottom: 10 }}>
                        <RouterProvider router={router} />
                    </Box>

                    <Footer />
                </Box>
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;
