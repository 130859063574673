import { AppBar, Box, Button, useMediaQuery } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { ExcludedLinksInLists, navItems } from '../pages/Routes';
import { AppointmentButton } from './AppointmentButton';
import { FullLogo } from './FullLogo';
import { LeftDrawer } from './LeftDrawer';

export const TopBar = () => {
    const theme = useTheme();
    const textColor = theme.palette.text.primary;

    const wideEnough = useMediaQuery(theme.breakpoints.up('topBar'));

    return (
        <AppBar
            position="relative"
            sx={{ zIndex: 100 }}
        >
            <Toolbar style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                <LeftDrawer />
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: wideEnough ? 'space-between' : 'center', width: '100%' }}>
                    <FullLogo />
                    {wideEnough ? (
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {navItems
                                .filter((item) => !ExcludedLinksInLists.includes(item.path))
                                .map((item) => (
                                    <Button
                                        key={item.title}
                                        sx={{ color: textColor }}
                                        href={`/${item.path}`}
                                    >
                                        {item.title}
                                    </Button>
                                ))}
                            <AppointmentButton marginLeft={4} />
                        </Box>
                    ) : null}
                </Box>
            </Toolbar>
        </AppBar>
    );
};
export default TopBar;
