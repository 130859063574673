import { Box } from '@mui/material';
import { HelmetHeader } from 'components/HelmetHeader';
import { navItems, Paths } from './Routes';

export const Privacy = () => {
    const navItem = navItems.find((ni) => ni.path === Paths.privacy);

    const title = navItem?.title ?? '';
    return (
        <main id="main">
            <HelmetHeader
                title={title}
                description="Passing Paws In-Home Pet Euthanasia's Privacy Policy"
                relPath={navItem?.path}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ marginLeft: 15, marginRight: 15 }}>
                    <style
                        dangerouslySetInnerHTML={{
                            __html: "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Poppins !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Poppins !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Poppins !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Poppins !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Poppins !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Poppins !important;\nword-break: break-word !important;\n        }\n",
                        }}
                    />
                    <span
                        style={{
                            display: 'block',
                            margin: '0 auto 3.125rem',
                            width: '11.125rem',
                            height: '2.375rem',
                            background: 'url(data:image/svg+xml',
                        }}
                    />
                    <div data-custom-class="body">
                        <div>
                            <strong>
                                <span style={{ fontSize: 26 }}>
                                    <span data-custom-class="title">
                                        <span className="block-component" />
                                        <span className="question">PRIVACY POLICY</span>
                                        <span className="statement-end-if-in-editor" />
                                    </span>
                                </span>
                            </strong>
                        </div>
                        <div>
                            <br />
                        </div>
                        <div>
                            <span style={{ color: 'rgb(127, 127, 127)' }}>
                                <strong>
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="subtitle">
                                            Last updated <span className="question">August 20, 2024</span>
                                        </span>
                                    </span>
                                </strong>
                            </span>
                        </div>
                        <div>
                            <br />
                        </div>
                        <div>
                            <br />
                        </div>
                        <div>
                            <br />
                        </div>
                        <div style={{ lineHeight: '1.5' }}>
                            <span style={{ color: 'rgb(127, 127, 127)' }}>
                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        This privacy notice for{' '}
                                        <span className="question">
                                            Passing Paws In-Home Pet Euthanasia, LLC
                                            <span className="block-component" />
                                        </span>{' '}
                                        (<span className="block-component" />"<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"
                                        <span className="statement-end-if-in-editor" />
                                    </span>
                                    <span data-custom-class="body_text">
                                        ), describes how and why we might collect, store, use, and/or share (<span className="block-component" />"
                                        <strong>process</strong>"
                                        <span className="statement-end-if-in-editor" />) your information when you use our services (
                                        <span className="block-component" />"<strong>Services</strong>"
                                        <span className="statement-end-if-in-editor" />
                                        ), such as when you:
                                    </span>
                                </span>
                            </span>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: 'rgb(127, 127, 127)' }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <span className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <ul>
                            <li
                                data-custom-class="body_text"
                                style={{ lineHeight: '1.5' }}
                            >
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            Visit our website
                                            <span className="block-component" /> at{' '}
                                            <span style={{ color: 'rgb(0, 58, 250)' }}>
                                                <span className="question">
                                                    <a
                                                        href="http://www.passingpawsihe.com"
                                                        target="_blank"
                                                        data-custom-class="link"
                                                        rel="noreferrer"
                                                    >
                                                        http://www.passingpawsihe.com
                                                    </a>
                                                </span>
                                            </span>
                                            <span style={{ fontSize: 15 }}>
                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                    <span data-custom-class="body_text">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                <span className="statement-end-if-in-editor">
                                                                    , or any website of ours that links to this privacy notice
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <div>
                            <span className="block-component">
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ fontSize: 15 }}>
                                        <span style={{ color: 'rgb(127, 127, 127)' }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                    <span data-custom-class="body_text">
                                                        <span className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                <span data-custom-class="body_text">
                                                    <span className="block-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <ul>
                                <li
                                    data-custom-class="body_text"
                                    style={{ lineHeight: '1.5' }}
                                >
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                Engage with us in other related ways, including any sales, marketing, or events
                                                <span style={{ fontSize: 15 }}>
                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                        <span data-custom-class="body_text">
                                                            <span style={{ fontSize: 15 }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                    <span className="statement-end-if-in-editor" />
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                                        <span data-custom-class="body_text">
                                            <strong>Questions or concerns? </strong>Reading this privacy notice will help you understand your privacy
                                            rights and choices. If you do not agree with our policies and practices, please do not use our Services.
                                            <span className="block-component" /> If you still have any questions or concerns, please contact us at{' '}
                                            <span className="question">info@passingpawsihe.com</span>.
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <strong>
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
                                    </span>
                                </strong>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>
                                            <em>
                                                This summary provides key points from our privacy notice, but you can find out more details about any
                                                of these topics by clicking the link following each key point or by using our{' '}
                                            </em>
                                        </strong>
                                    </span>
                                </span>
                                <a
                                    data-custom-class="link"
                                    href="#toc"
                                >
                                    <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <strong>
                                                <em>table of contents</em>
                                            </strong>
                                        </span>
                                    </span>
                                </a>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>
                                            <em> below to find the section you are looking for.</em>
                                        </strong>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we
                                        may process personal information depending on how you interact with us and the Services, the choices you make,
                                        and the products and features you use. Learn more about{' '}
                                    </span>
                                </span>
                                <a
                                    data-custom-class="link"
                                    href="#personalinfo"
                                >
                                    <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                                        <span data-custom-class="body_text">personal information you disclose to us</span>
                                    </span>
                                </a>
                                <span data-custom-class="body_text">.</span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>Do we process any sensitive personal information?</strong> <span className="block-component" />
                                        We do not process sensitive personal information.
                                        <span className="else-block" />
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>Do we collect any information from third parties?</strong> <span className="block-component" />
                                        We may collect information from public databases, marketing partners, social media platforms, and other
                                        outside sources. Learn more about{' '}
                                    </span>
                                </span>
                                <a
                                    data-custom-class="link"
                                    href="#othersources"
                                >
                                    <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                                        <span data-custom-class="body_text">information collected from other sources</span>
                                    </span>
                                </a>
                                <span data-custom-class="body_text">.</span>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <span className="statement-end-if-in-editor" />
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>How do we process your information?</strong> We process your information to provide, improve, and
                                        administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We
                                        may also process your information for other purposes with your consent. We process your information only when
                                        we have a valid legal reason to do so. Learn more about{' '}
                                    </span>
                                </span>
                                <a
                                    data-custom-class="link"
                                    href="#infouse"
                                >
                                    <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                                        <span data-custom-class="body_text">how we process your information</span>
                                    </span>
                                </a>
                                <span data-custom-class="body_text">.</span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>
                                            In what situations and with which <span className="block-component" />
                                            parties do we share personal information?
                                        </strong>{' '}
                                        We do not share information with <span className="block-component" />
                                        third parties. Learn more about{' '}
                                    </span>
                                </span>
                                <a
                                    data-custom-class="link"
                                    href="#whoshare"
                                >
                                    <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                                        <span data-custom-class="body_text">when and with whom we share your personal information</span>
                                    </span>
                                </a>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        .<span className="block-component" />
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>How do we keep your information safe?</strong> We have <span className="block-component" />
                                        organizational
                                        <span className="statement-end-if-in-editor" /> and technical processes and procedures in place to protect
                                        your personal information. However, no electronic transmission over the internet or information storage
                                        technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                                        cybercriminals, or other <span className="block-component" />
                                        unauthorized
                                        <span className="statement-end-if-in-editor" /> third parties will not be able to defeat our security and
                                        improperly collect, access, steal, or modify your information. Learn more about{' '}
                                    </span>
                                </span>
                                <a
                                    data-custom-class="link"
                                    href="#infosafe"
                                >
                                    <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                                        <span data-custom-class="body_text">how we keep your information safe</span>
                                    </span>
                                </a>
                                <span data-custom-class="body_text">.</span>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <span className="statement-end-if-in-editor" />
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable
                                        privacy law may mean you have certain rights regarding your personal information. Learn more about{' '}
                                    </span>
                                </span>
                                <a
                                    data-custom-class="link"
                                    href="#privacyrights"
                                >
                                    <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                                        <span data-custom-class="body_text">your privacy rights</span>
                                    </span>
                                </a>
                                <span data-custom-class="body_text">.</span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by{' '}
                                        <span className="block-component" />
                                        visiting{' '}
                                        <span style={{ color: 'rgb(0, 58, 250)' }}>
                                            <span className="question">
                                                <a
                                                    href="https://form.jotform.com/233013525423141"
                                                    target="_blank"
                                                    data-custom-class="link"
                                                    rel="noreferrer"
                                                >
                                                    https://form.jotform.com/233013525423141
                                                </a>
                                            </span>
                                        </span>
                                        <span className="else-block" />, or by contacting us. We will consider and act upon any request in accordance
                                        with applicable data protection laws.
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">Want to learn more about what we do with any information we collect? </span>
                                </span>
                                <a
                                    data-custom-class="link"
                                    href="#toc"
                                >
                                    <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                                        <span data-custom-class="body_text">Review the privacy notice in full</span>
                                    </span>
                                </a>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">.</span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div
                                id="toc"
                                style={{ lineHeight: '1.5' }}
                            >
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                                        <span style={{ color: 'rgb(0, 0, 0)' }}>
                                            <strong>
                                                <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <a
                                        data-custom-class="link"
                                        href="#infocollect"
                                    >
                                        <span style={{ color: 'rgb(0, 58, 250)' }}>1. WHAT INFORMATION DO WE COLLECT?</span>
                                    </a>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <a
                                        data-custom-class="link"
                                        href="#infouse"
                                    >
                                        <span style={{ color: 'rgb(0, 58, 250)' }}>
                                            2. HOW DO WE PROCESS YOUR INFORMATION?
                                            <span className="block-component" />
                                        </span>
                                    </a>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: 'rgb(0, 58, 250)' }}>
                                        <a
                                            data-custom-class="link"
                                            href="#whoshare"
                                        >
                                            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                                        </a>
                                    </span>
                                    <span data-custom-class="body_text">
                                        <span className="block-component" />
                                        <span style={{ color: 'rgb(127, 127, 127)' }}>
                                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                <span data-custom-class="body_text">
                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                        <span className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <span className="block-component" />
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                            <span className="block-component" />
                                                        </span>
                                                    </span>
                                                    <span className="block-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <a
                                        data-custom-class="link"
                                        href="#inforetain"
                                    >
                                        <span style={{ color: 'rgb(0, 58, 250)' }}>4. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                                    </a>
                                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                        <span className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <a
                                        data-custom-class="link"
                                        href="#infosafe"
                                    >
                                        <span style={{ color: 'rgb(0, 58, 250)' }}>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                                    </a>
                                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                    <span className="statement-end-if-in-editor" />
                                                    <span className="block-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <a
                                        data-custom-class="link"
                                        href="#infominors"
                                    >
                                        <span style={{ color: 'rgb(0, 58, 250)' }}>6. DO WE COLLECT INFORMATION FROM MINORS?</span>
                                    </a>
                                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                    <span className="statement-end-if-in-editor" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: 'rgb(0, 58, 250)' }}>
                                        <a
                                            data-custom-class="link"
                                            href="#privacyrights"
                                        >
                                            7. WHAT ARE YOUR PRIVACY RIGHTS?
                                        </a>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <a
                                        data-custom-class="link"
                                        href="#DNT"
                                    >
                                        <span style={{ color: 'rgb(0, 58, 250)' }}>
                                            8. CONTROLS FOR DO-NOT-TRACK FEATURES
                                            <span className="block-component" />
                                        </span>
                                    </a>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <a
                                        data-custom-class="link"
                                        href="#uslaws"
                                    >
                                        <span style={{ color: 'rgb(0, 58, 250)' }}>9. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span>
                                    </a>
                                </span>
                                <span className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span className="block-component">
                                    <span style={{ fontSize: 15 }} />
                                </span>
                                <span className="block-component" />
                                <span className="block-component" />
                                <span className="block-component" />
                                <span className="block-component" />
                                <span className="block-component" />
                                <span className="block-component" />
                                <span className="block-component" />
                                <span className="block-component" />
                                <span className="block-component" />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <a
                                        data-custom-class="link"
                                        href="#policyupdates"
                                    >
                                        <span style={{ color: 'rgb(0, 58, 250)' }}>10. DO WE MAKE UPDATES TO THIS NOTICE?</span>
                                    </a>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <a
                                    data-custom-class="link"
                                    href="#contact"
                                >
                                    <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                                </a>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <a
                                    data-custom-class="link"
                                    href="#request"
                                >
                                    <span style={{ color: 'rgb(0, 58, 250)' }}>
                                        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                                    </span>
                                </a>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div
                                id="infocollect"
                                style={{ lineHeight: '1.5' }}
                            >
                                <span style={{ color: 'rgb(0, 0, 0)' }}>
                                    <span style={{ color: 'rgb(0, 0, 0)', fontSize: 15 }}>
                                        <span style={{ fontSize: 15, color: 'rgb(0, 0, 0)' }}>
                                            <span style={{ fontSize: 15, color: 'rgb(0, 0, 0)' }}>
                                                <span
                                                    id="control"
                                                    style={{ color: 'rgb(0, 0, 0)' }}
                                                >
                                                    <strong>
                                                        <span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span>
                                                    </strong>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div
                                id="personalinfo"
                                style={{ lineHeight: '1.5' }}
                            >
                                <span
                                    data-custom-class="heading_2"
                                    style={{ color: 'rgb(0, 0, 0)' }}
                                >
                                    <span style={{ fontSize: 15 }}>
                                        <strong>Personal information you disclose to us</strong>
                                    </span>
                                </span>
                            </div>
                            <div>
                                <div>
                                    <br />
                                </div>
                                <div style={{ lineHeight: '1.5' }}>
                                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                                        <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                        <span data-custom-class="body_text">
                                                            <strong>
                                                                <em>In Short:</em>
                                                            </strong>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                        <span data-custom-class="body_text">
                                                            <strong>
                                                                <em> </em>
                                                            </strong>
                                                            <em>We collect personal information that you provide to us.</em>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            We collect personal information that you voluntarily provide to us when you{' '}
                                            <span style={{ fontSize: 15 }}>
                                                <span className="block-component" />
                                            </span>
                                        </span>
                                        <span data-custom-class="body_text">
                                            express an interest in obtaining information about us or our products and Services, when you participate
                                            in activities on the Services, or otherwise when you contact us.
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                                <span className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            <strong>Personal Information Provided by You.</strong> The personal information that we collect depends on
                                            the context of your interactions with us and the Services, the choices you make, and the products and
                                            features you use. The personal information we collect may include the following:
                                            <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                    <span className="forloop-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <ul>
                                <li
                                    data-custom-class="body_text"
                                    style={{ lineHeight: '1.5' }}
                                >
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <span className="question">names</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                    <span className="forloop-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <ul>
                                <li
                                    data-custom-class="body_text"
                                    style={{ lineHeight: '1.5' }}
                                >
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <span className="question">phone numbers</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                    <span className="forloop-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <ul>
                                <li
                                    data-custom-class="body_text"
                                    style={{ lineHeight: '1.5' }}
                                >
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <span className="question">email addresses</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                    <span className="forloop-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <ul>
                                <li
                                    data-custom-class="body_text"
                                    style={{ lineHeight: '1.5' }}
                                >
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <span className="question">mailing addresses</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                    <span className="forloop-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <ul>
                                <li
                                    data-custom-class="body_text"
                                    style={{ lineHeight: '1.5' }}
                                >
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <span className="question">contact preferences</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                    <span className="forloop-component" />
                                                </span>
                                                <span data-custom-class="body_text">
                                                    <span className="statement-end-if-in-editor" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div
                                id="sensitiveinfo"
                                style={{ lineHeight: '1.5' }}
                            >
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <strong>Sensitive Information.</strong> <span className="block-component" />
                                        We do not process sensitive information.
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <span className="else-block" />
                                    </span>
                                </span>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                    <span className="block-component">
                                                        <span className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                    <span className="block-component" />
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            All personal information that you provide to us must be true, complete, and accurate, and you must notify
                                            us of any changes to such personal information.
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            <span className="block-component" />
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span
                                    data-custom-class="heading_2"
                                    style={{ color: 'rgb(0, 0, 0)' }}
                                >
                                    <span style={{ fontSize: 15 }}>
                                        <strong>Information automatically collected</strong>
                                    </span>
                                </span>
                            </div>
                            <div>
                                <div>
                                    <br />
                                </div>
                                <div style={{ lineHeight: '1.5' }}>
                                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                                        <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                        <span data-custom-class="body_text">
                                                            <strong>
                                                                <em>In Short:</em>
                                                            </strong>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                        <span data-custom-class="body_text">
                                                            <strong>
                                                                <em> </em>
                                                            </strong>
                                                            <em>
                                                                Some information such as your Internet Protocol (IP) address and/or browser and device
                                                                characteristics is collected automatically when you visit our Services.
                                                            </em>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            We automatically collect certain information when you visit, use, or navigate the Services. This
                                            information does not reveal your specific identity (like your name or contact information) but may include
                                            device and usage information, such as your IP address, browser and device characteristics, operating
                                            system, language preferences, referring URLs, device name, country, location, information about how and
                                            when you use our Services, and other technical information. This information is primarily needed to
                                            maintain the security and operation of our Services, and for our internal analytics and reporting
                                            purposes.
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            <span className="block-component" />
                                        </span>
                                    </span>
                                    <span data-custom-class="body_text">
                                        <span className="block-component" />
                                    </span>
                                </span>
                            </div>
                            <div style={{ lineHeight: '1.5' }}>
                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span data-custom-class="body_text">
                                            The information we collect includes:
                                            <span className="block-component" />
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <ul>
                                <li
                                    data-custom-class="body_text"
                                    style={{ lineHeight: '1.5' }}
                                >
                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                                <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance
                                                information our servers automatically collect when you access or use our Services and which we record
                                                in log files. Depending on how you interact with us, this log data may include your IP address, device
                                                information, browser type, and settings and information about your activity in the Services
                                                <span style={{ fontSize: 15 }}> </span>(such as the date/time stamps associated with your usage, pages
                                                and files viewed, searches, and other actions you take such as which features you use), device event
                                                information (such as system activity, error reports (sometimes called{' '}
                                                <span className="block-component" />
                                                "crash dumps"
                                                <span className="statement-end-if-in-editor" />
                                                ), and hardware settings).
                                                <span style={{ fontSize: 15 }}>
                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                        <span data-custom-class="body_text">
                                                            <span style={{ fontSize: 15 }}>
                                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                    <span className="statement-end-if-in-editor" />
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <div style={{ lineHeight: '1.5' }}>
                                <span className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                    </span>
                                </span>
                                <div style={{ lineHeight: '1.5' }}>
                                    <span className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                        </span>
                                    </span>
                                    <div>
                                        <span className="block-component">
                                            <span style={{ fontSize: 15 }} />
                                        </span>
                                        <span className="statement-end-if-in-editor" />
                                        <span className="block-component">
                                            <span style={{ fontSize: 15 }} />
                                        </span>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                <span data-custom-class="body_text">
                                                    <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                                                        <span data-custom-class="body_text">
                                                            <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                                                                <span data-custom-class="body_text">
                                                                    <span className="statement-end-if-in-editor">
                                                                        <span className="block-component" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <div
                                        id="othersources"
                                        style={{ lineHeight: '1.5' }}
                                    >
                                        <span
                                            data-custom-class="heading_2"
                                            style={{ color: 'rgb(0, 0, 0)' }}
                                        >
                                            <span style={{ fontSize: 15 }}>
                                                <strong>Information collected from other sources</strong>
                                            </span>
                                        </span>
                                    </div>
                                    <div>
                                        <div>
                                            <br />
                                        </div>
                                        <div style={{ lineHeight: '1.5' }}>
                                            <span style={{ color: 'rgb(127, 127, 127)' }}>
                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        <em>In Short: </em>
                                                                    </strong>
                                                                    <em>
                                                                        We may collect limited data from public databases, marketing partners,{' '}
                                                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <em>
                                                                                    <span data-custom-class="body_text">
                                                                                        <span className="block-component" />
                                                                                    </span>
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                        and other outside sources.
                                                                    </em>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ lineHeight: '1.5' }}>
                                        <br />
                                    </div>
                                    <div style={{ lineHeight: '1.5' }}>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                <span data-custom-class="body_text">
                                                    In order to enhance our ability to provide relevant marketing, offers, and services to you and
                                                    update our records, we may obtain information about you from other sources, such as public
                                                    databases, joint marketing partners, affiliate programs, data providers,
                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text">
                                                                <span className="block-component" />
                                                            </span>
                                                        </span>{' '}
                                                    </span>
                                                    and from other third parties. This information includes mailing addresses, job titles, email
                                                    addresses, phone numbers, intent data (or user <span className="block-component" />
                                                    behavior
                                                    <span className="statement-end-if-in-editor" /> data), Internet Protocol (IP) addresses, social
                                                    media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and
                                                    event promotion.
                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text">
                                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text">
                                                                            <span className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <div style={{ lineHeight: '1.5' }}>
                                        <br />
                                    </div>
                                    <div style={{ lineHeight: '1.5' }}>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                <span data-custom-class="body_text">
                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text">
                                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                        <span className="block-component">
                                                                            <span
                                                                                style={{
                                                                                    color: 'rgb(89, 89, 89)',
                                                                                    fontSize: 15,
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            color: 'rgb(89, 89, 89)',
                                                                                            fontSize: 15,
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            <span className="statement-end-if-in-editor">
                                                                                                <span className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <span className="block-component" />
                                            </span>
                                        </span>
                                    </div>
                                    <div style={{ lineHeight: '1.5' }}>
                                        <br />
                                    </div>
                                    <div
                                        id="infouse"
                                        style={{ lineHeight: '1.5' }}
                                    >
                                        <span style={{ color: 'rgb(127, 127, 127)' }}>
                                            <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                        <span
                                                            id="control"
                                                            style={{ color: 'rgb(0, 0, 0)' }}
                                                        >
                                                            <strong>
                                                                <span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span>
                                                            </strong>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <div>
                                        <div style={{ lineHeight: '1.5' }}>
                                            <br />
                                        </div>
                                        <div style={{ lineHeight: '1.5' }}>
                                            <span style={{ color: 'rgb(127, 127, 127)' }}>
                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        <em>In Short: </em>
                                                                    </strong>
                                                                    <em>
                                                                        We process your information to provide, improve, and administer our Services,
                                                                        communicate with you, for security and fraud prevention, and to comply with
                                                                        law. We may also process your information for other purposes with your
                                                                        consent.
                                                                    </em>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ lineHeight: '1.5' }}>
                                        <br />
                                    </div>
                                    <div style={{ lineHeight: '1.5' }}>
                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                <span data-custom-class="body_text">
                                                    <strong>
                                                        We process your personal information for a variety of reasons, depending on how you interact
                                                        with our Services, including:
                                                    </strong>
                                                    <span className="block-component" />
                                                </span>
                                            </span>
                                        </span>
                                        <div style={{ lineHeight: '1.5' }}>
                                            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                    <span data-custom-class="body_text">
                                                        <span className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                        <ul>
                                            <li
                                                data-custom-class="body_text"
                                                style={{ lineHeight: '1.5' }}
                                            >
                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                        <span data-custom-class="body_text">
                                                            <strong>To deliver and facilitate delivery of services to the user. </strong>
                                                            We may process your information to provide you with the requested service.
                                                            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                                    <span data-custom-class="body_text">
                                                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: 'rgb(89, 89, 89)',
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <span className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </li>
                                        </ul>
                                        <div style={{ lineHeight: '1.5' }}>
                                            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                    <span data-custom-class="body_text">
                                                        <span className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                            <div style={{ lineHeight: '1.5' }}>
                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                        <span data-custom-class="body_text">
                                                            <span className="block-component" />
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <ul>
                                                <li
                                                    data-custom-class="body_text"
                                                    style={{ lineHeight: '1.5' }}
                                                >
                                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                            <span data-custom-class="body_text">
                                                                <strong>To respond to user inquiries/offer support to users. </strong>
                                                                We may process your information to respond to your inquiries and solve any potential
                                                                issues you might have with the requested service.
                                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                                        <span data-custom-class="body_text">
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                            <div style={{ lineHeight: '1.5' }}>
                                                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                                                        <span data-custom-class="body_text">
                                                            <span className="block-component" />
                                                        </span>
                                                    </span>
                                                </span>
                                                <div style={{ lineHeight: '1.5' }}>
                                                    <span className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </span>
                                                    <div style={{ lineHeight: '1.5' }}>
                                                        <span className="block-component">
                                                            <span style={{ fontSize: 15 }} />
                                                        </span>
                                                        <div style={{ lineHeight: '1.5' }}>
                                                            <span className="block-component">
                                                                <span style={{ fontSize: 15 }} />
                                                            </span>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                                <span className="block-component">
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text" />
                                                                    </span>
                                                                </span>
                                                                <p style={{ fontSize: 15, lineHeight: '1.5' }}>
                                                                    <span className="block-component">
                                                                        <span style={{ fontSize: 15 }} />
                                                                    </span>
                                                                </p>
                                                                <p style={{ fontSize: 15, lineHeight: '1.5' }}>
                                                                    <span className="block-component">
                                                                        <span style={{ fontSize: 15 }} />
                                                                    </span>
                                                                </p>
                                                                <p style={{ fontSize: 15, lineHeight: '1.5' }}>
                                                                    <span className="block-component" />
                                                                </p>
                                                                <ul>
                                                                    <li
                                                                        data-custom-class="body_text"
                                                                        style={{ lineHeight: '1.5' }}
                                                                    >
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <strong>To request feedback. </strong>We may process your
                                                                                        information when necessary to request feedback and to contact
                                                                                        you about your use of our Services.
                                                                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <p style={{ fontSize: 15, lineHeight: '1.5' }}>
                                                                    <span className="block-component" />
                                                                </p>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                    <span className="block-component">
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span data-custom-class="body_text" />
                                                                        </span>
                                                                    </span>
                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                        <span className="block-component">
                                                                            <span style={{ fontSize: 15 }} />
                                                                        </span>
                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                            <span className="block-component">
                                                                                <span style={{ fontSize: 15 }} />
                                                                            </span>
                                                                            <div style={{ lineHeight: '1.5' }}>
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span className="block-component">
                                                                                        <span data-custom-class="body_text" />
                                                                                    </span>
                                                                                </span>
                                                                                <div style={{ lineHeight: '1.5' }}>
                                                                                    <span className="block-component">
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text" />
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                                <ul>
                                                                                    <li
                                                                                        data-custom-class="body_text"
                                                                                        style={{ lineHeight: '1.5' }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <strong>To post testimonials.</strong> We post
                                                                                                testimonials on our Services that may contain personal
                                                                                                information.
                                                                                                <span className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <div style={{ lineHeight: '1.5' }}>
                                                                                    <span className="block-component">
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text" />
                                                                                        </span>
                                                                                    </span>
                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                        <span className="block-component">
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text" />
                                                                                            </span>
                                                                                        </span>
                                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                                            <span className="block-component">
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text" />
                                                                                                </span>
                                                                                            </span>
                                                                                            <div style={{ lineHeight: '1.5' }}>
                                                                                                <span className="block-component">
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text" />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                            <ul>
                                                                                                <li
                                                                                                    data-custom-class="body_text"
                                                                                                    style={{ lineHeight: '1.5' }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <strong>
                                                                                                                To evaluate and improve our Services,
                                                                                                                products, marketing, and your
                                                                                                                experience.
                                                                                                            </strong>{' '}
                                                                                                            We may process your information when we
                                                                                                            believe it is necessary to identify usage
                                                                                                            trends, determine the effectiveness of our
                                                                                                            promotional campaigns, and to evaluate and
                                                                                                            improve our Services, products, marketing,
                                                                                                            and your experience.
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <span className="statement-end-if-in-editor">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <div style={{ lineHeight: '1.5' }}>
                                                                                                <span className="block-component">
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text" />
                                                                                                    </span>
                                                                                                </span>
                                                                                                <div style={{ lineHeight: '1.5' }}>
                                                                                                    <span className="block-component">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                                        <span className="block-component">
                                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                                <span data-custom-class="body_text" />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        <li
                                                                                                            data-custom-class="body_text"
                                                                                                            style={{ lineHeight: '1.5' }}
                                                                                                        >
                                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <strong>
                                                                                                                        To determine the effectiveness
                                                                                                                        of our marketing and
                                                                                                                        promotional campaigns.
                                                                                                                    </strong>{' '}
                                                                                                                    We may process your information to
                                                                                                                    better understand how to provide
                                                                                                                    marketing and promotional
                                                                                                                    campaigns that are most relevant
                                                                                                                    to you.
                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                                                        <span className="block-component">
                                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                                <span data-custom-class="body_text" />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                                                            <span className="block-component">
                                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                </span>
                                                                                                            </span>
                                                                                                            <div style={{ lineHeight: '1.5' }}>
                                                                                                                <span className="block-component">
                                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                                <div style={{ lineHeight: '1.5' }}>
                                                                                                                    <span className="block-component">
                                                                                                                        <span
                                                                                                                            style={{ fontSize: 15 }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                    <span className="block-component">
                                                                                                                        <span
                                                                                                                            style={{ fontSize: 15 }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                    <span className="block-component">
                                                                                                                        <span
                                                                                                                            style={{ fontSize: 15 }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                    <span className="block-component">
                                                                                                                        <span
                                                                                                                            style={{ fontSize: 15 }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div style={{ lineHeight: '1.5' }}>
                                                                                                                    <br />
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    id="whoshare"
                                                                                                                    style={{ lineHeight: '1.5' }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color: 'rgb(127, 127, 127)',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                fontSize: 15,
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15,
                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        id="control"
                                                                                                                                        style={{
                                                                                                                                            color: 'rgb(0, 0, 0)',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <strong>
                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                3.
                                                                                                                                                WHEN
                                                                                                                                                AND
                                                                                                                                                WITH
                                                                                                                                                WHOM
                                                                                                                                                DO WE
                                                                                                                                                SHARE
                                                                                                                                                YOUR
                                                                                                                                                PERSONAL
                                                                                                                                                INFORMATION?
                                                                                                                                            </span>
                                                                                                                                        </strong>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div style={{ lineHeight: '1.5' }}>
                                                                                                                    <br />
                                                                                                                </div>
                                                                                                                <div style={{ lineHeight: '1.5' }}>
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: 15,
                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <strong>
                                                                                                                                    <em>In Short:</em>
                                                                                                                                </strong>
                                                                                                                                <em>
                                                                                                                                    {' '}
                                                                                                                                    We will not share
                                                                                                                                    your information
                                                                                                                                    with any third
                                                                                                                                    parties.
                                                                                                                                </em>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                {/*<div style={{ lineHeight: '1.5' }}>
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: 15,
                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <span className="block-component" />
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div style={{ lineHeight: '1.5' }}>
                                                                                                                    <br />
                                                                                                                </div>
                                                                                                                  <div style={{ lineHeight: '1.5' }}>
                                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            We{' '}
                                                                                                                            <span className="block-component" />
                                                                                                                            may need to share your
                                                                                                                            personal information in
                                                                                                                            the following situations:
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <ul>
                                                                                                                    <li
                                                                                                                        data-custom-class="body_text"
                                                                                                                        style={{
                                                                                                                            lineHeight: '1.5',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{ fontSize: 15 }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <strong>
                                                                                                                                    Business
                                                                                                                                    Transfers.
                                                                                                                                </strong>{' '}
                                                                                                                                We may share or
                                                                                                                                transfer your
                                                                                                                                information in
                                                                                                                                connection with, or
                                                                                                                                during negotiations
                                                                                                                                of, any merger, sale
                                                                                                                                of company assets,
                                                                                                                                financing, or
                                                                                                                                acquisition of all or
                                                                                                                                a portion of our
                                                                                                                                business to another
                                                                                                                                company.
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </li>
                                                                                                                </ul> */}
                                                                                                                <div style={{ lineHeight: '1.5' }}>
                                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            <span className="block-component" />
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            lineHeight: '1.5',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{ fontSize: 15 }}
                                                                                                                        >
                                                                                                                            <span className="block-component">
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: '1.5',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span className="block-component">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: '1.5',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span className="block-component">
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            fontSize: 15,
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        lineHeight:
                                                                                                                                            '1.5',
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span className="block-component">
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                fontSize: 15,
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span className="block-component">
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span className="block-component" />
                                                                                                                                        </span>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                            fontSize: 15,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                fontSize: 15,
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="inforetain"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color: 'rgb(127, 127, 127)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color: 'rgb(0, 0, 0)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    4.
                                                                                                                                                                    HOW
                                                                                                                                                                    LONG
                                                                                                                                                                    DO
                                                                                                                                                                    WE
                                                                                                                                                                    KEEP
                                                                                                                                                                    YOUR
                                                                                                                                                                    INFORMATION?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:{' '}
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        We
                                                                                                                                                        keep
                                                                                                                                                        your
                                                                                                                                                        information
                                                                                                                                                        for
                                                                                                                                                        as
                                                                                                                                                        long
                                                                                                                                                        as
                                                                                                                                                        necessary
                                                                                                                                                        to{' '}
                                                                                                                                                        <span className="block-component" />
                                                                                                                                                        fulfill
                                                                                                                                                        <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                        the
                                                                                                                                                        purposes
                                                                                                                                                        outlined
                                                                                                                                                        in
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice
                                                                                                                                                        unless
                                                                                                                                                        otherwise
                                                                                                                                                        required
                                                                                                                                                        by
                                                                                                                                                        law.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We
                                                                                                                                                    will
                                                                                                                                                    only
                                                                                                                                                    keep
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    for
                                                                                                                                                    as
                                                                                                                                                    long
                                                                                                                                                    as
                                                                                                                                                    it
                                                                                                                                                    is
                                                                                                                                                    necessary
                                                                                                                                                    for
                                                                                                                                                    the
                                                                                                                                                    purposes
                                                                                                                                                    set
                                                                                                                                                    out
                                                                                                                                                    in
                                                                                                                                                    this
                                                                                                                                                    privacy
                                                                                                                                                    notice,
                                                                                                                                                    unless
                                                                                                                                                    a
                                                                                                                                                    longer
                                                                                                                                                    retention
                                                                                                                                                    period
                                                                                                                                                    is
                                                                                                                                                    required
                                                                                                                                                    or
                                                                                                                                                    permitted
                                                                                                                                                    by
                                                                                                                                                    law
                                                                                                                                                    (such
                                                                                                                                                    as
                                                                                                                                                    tax,
                                                                                                                                                    accounting,
                                                                                                                                                    or
                                                                                                                                                    other
                                                                                                                                                    legal
                                                                                                                                                    requirements).
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    When
                                                                                                                                                    we
                                                                                                                                                    have
                                                                                                                                                    no
                                                                                                                                                    ongoing
                                                                                                                                                    legitimate
                                                                                                                                                    business
                                                                                                                                                    need
                                                                                                                                                    to
                                                                                                                                                    process
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information,
                                                                                                                                                    we
                                                                                                                                                    will
                                                                                                                                                    either
                                                                                                                                                    delete
                                                                                                                                                    or{' '}
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                    anonymize
                                                                                                                                                    <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                    such
                                                                                                                                                    information,
                                                                                                                                                    or,
                                                                                                                                                    if
                                                                                                                                                    this
                                                                                                                                                    is
                                                                                                                                                    not
                                                                                                                                                    possible
                                                                                                                                                    (for
                                                                                                                                                    example,
                                                                                                                                                    because
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    has
                                                                                                                                                    been
                                                                                                                                                    stored
                                                                                                                                                    in
                                                                                                                                                    backup
                                                                                                                                                    archives),
                                                                                                                                                    then
                                                                                                                                                    we
                                                                                                                                                    will
                                                                                                                                                    securely
                                                                                                                                                    store
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    and
                                                                                                                                                    isolate
                                                                                                                                                    it
                                                                                                                                                    from
                                                                                                                                                    any
                                                                                                                                                    further
                                                                                                                                                    processing
                                                                                                                                                    until
                                                                                                                                                    deletion
                                                                                                                                                    is
                                                                                                                                                    possible.
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="block-component" />
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="infosafe"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color: 'rgb(127, 127, 127)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color: 'rgb(0, 0, 0)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    5.
                                                                                                                                                                    HOW
                                                                                                                                                                    DO
                                                                                                                                                                    WE
                                                                                                                                                                    KEEP
                                                                                                                                                                    YOUR
                                                                                                                                                                    INFORMATION
                                                                                                                                                                    SAFE?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:{' '}
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        We
                                                                                                                                                        aim
                                                                                                                                                        to
                                                                                                                                                        protect
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        through
                                                                                                                                                        a
                                                                                                                                                        system
                                                                                                                                                        of{' '}
                                                                                                                                                        <span className="block-component" />
                                                                                                                                                        organizational
                                                                                                                                                        <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                        and
                                                                                                                                                        technical
                                                                                                                                                        security
                                                                                                                                                        measures.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We
                                                                                                                                                    have
                                                                                                                                                    implemented
                                                                                                                                                    appropriate
                                                                                                                                                    and
                                                                                                                                                    reasonable
                                                                                                                                                    technical
                                                                                                                                                    and{' '}
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                    organizational
                                                                                                                                                    <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                    security
                                                                                                                                                    measures
                                                                                                                                                    designed
                                                                                                                                                    to
                                                                                                                                                    protect
                                                                                                                                                    the
                                                                                                                                                    security
                                                                                                                                                    of
                                                                                                                                                    any
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    we
                                                                                                                                                    process.
                                                                                                                                                    However,
                                                                                                                                                    despite
                                                                                                                                                    our
                                                                                                                                                    safeguards
                                                                                                                                                    and
                                                                                                                                                    efforts
                                                                                                                                                    to
                                                                                                                                                    secure
                                                                                                                                                    your
                                                                                                                                                    information,
                                                                                                                                                    no
                                                                                                                                                    electronic
                                                                                                                                                    transmission
                                                                                                                                                    over
                                                                                                                                                    the
                                                                                                                                                    Internet
                                                                                                                                                    or
                                                                                                                                                    information
                                                                                                                                                    storage
                                                                                                                                                    technology
                                                                                                                                                    can
                                                                                                                                                    be
                                                                                                                                                    guaranteed
                                                                                                                                                    to
                                                                                                                                                    be
                                                                                                                                                    100%
                                                                                                                                                    secure,
                                                                                                                                                    so
                                                                                                                                                    we
                                                                                                                                                    cannot
                                                                                                                                                    promise
                                                                                                                                                    or
                                                                                                                                                    guarantee
                                                                                                                                                    that
                                                                                                                                                    hackers,
                                                                                                                                                    cybercriminals,
                                                                                                                                                    or
                                                                                                                                                    other{' '}
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                    unauthorized
                                                                                                                                                    <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                    third
                                                                                                                                                    parties
                                                                                                                                                    will
                                                                                                                                                    not
                                                                                                                                                    be
                                                                                                                                                    able
                                                                                                                                                    to
                                                                                                                                                    defeat
                                                                                                                                                    our
                                                                                                                                                    security
                                                                                                                                                    and
                                                                                                                                                    improperly
                                                                                                                                                    collect,
                                                                                                                                                    access,
                                                                                                                                                    steal,
                                                                                                                                                    or
                                                                                                                                                    modify
                                                                                                                                                    your
                                                                                                                                                    information.
                                                                                                                                                    Although
                                                                                                                                                    we
                                                                                                                                                    will
                                                                                                                                                    do
                                                                                                                                                    our
                                                                                                                                                    best
                                                                                                                                                    to
                                                                                                                                                    protect
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information,
                                                                                                                                                    transmission
                                                                                                                                                    of
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    to
                                                                                                                                                    and
                                                                                                                                                    from
                                                                                                                                                    our
                                                                                                                                                    Services
                                                                                                                                                    is
                                                                                                                                                    at
                                                                                                                                                    your
                                                                                                                                                    own
                                                                                                                                                    risk.
                                                                                                                                                    You
                                                                                                                                                    should
                                                                                                                                                    only
                                                                                                                                                    access
                                                                                                                                                    the
                                                                                                                                                    Services
                                                                                                                                                    within
                                                                                                                                                    a
                                                                                                                                                    secure
                                                                                                                                                    environment.
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                            fontSize: 15,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <span className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="infominors"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color: 'rgb(127, 127, 127)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color: 'rgb(0, 0, 0)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    6.
                                                                                                                                                                    DO
                                                                                                                                                                    WE
                                                                                                                                                                    COLLECT
                                                                                                                                                                    INFORMATION
                                                                                                                                                                    FROM
                                                                                                                                                                    MINORS?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        {' '}
                                                                                                                                                        We
                                                                                                                                                        do
                                                                                                                                                        not
                                                                                                                                                        knowingly
                                                                                                                                                        collect
                                                                                                                                                        data
                                                                                                                                                        from
                                                                                                                                                        or
                                                                                                                                                        market
                                                                                                                                                        to{' '}
                                                                                                                                                        <span className="block-component" />
                                                                                                                                                        children
                                                                                                                                                        under
                                                                                                                                                        18
                                                                                                                                                        years
                                                                                                                                                        of
                                                                                                                                                        age
                                                                                                                                                        <span className="else-block" />

                                                                                                                                                        .
                                                                                                                                                    </em>
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We
                                                                                                                                                    do
                                                                                                                                                    not
                                                                                                                                                    knowingly
                                                                                                                                                    collect,
                                                                                                                                                    solicit
                                                                                                                                                    data
                                                                                                                                                    from,
                                                                                                                                                    or
                                                                                                                                                    market
                                                                                                                                                    to
                                                                                                                                                    children
                                                                                                                                                    under
                                                                                                                                                    18
                                                                                                                                                    years
                                                                                                                                                    of
                                                                                                                                                    age,
                                                                                                                                                    nor
                                                                                                                                                    do
                                                                                                                                                    we
                                                                                                                                                    knowingly
                                                                                                                                                    sell
                                                                                                                                                    such
                                                                                                                                                    personal
                                                                                                                                                    information.
                                                                                                                                                    By
                                                                                                                                                    using
                                                                                                                                                    the
                                                                                                                                                    Services,
                                                                                                                                                    you
                                                                                                                                                    represent
                                                                                                                                                    that
                                                                                                                                                    you
                                                                                                                                                    are
                                                                                                                                                    at
                                                                                                                                                    least
                                                                                                                                                    18
                                                                                                                                                    or
                                                                                                                                                    that
                                                                                                                                                    you
                                                                                                                                                    are
                                                                                                                                                    the
                                                                                                                                                    parent
                                                                                                                                                    or
                                                                                                                                                    guardian
                                                                                                                                                    of
                                                                                                                                                    such
                                                                                                                                                    a
                                                                                                                                                    minor
                                                                                                                                                    and
                                                                                                                                                    consent
                                                                                                                                                    to
                                                                                                                                                    such
                                                                                                                                                    minor
                                                                                                                                                    dependent
                                                                                                                                                    s
                                                                                                                                                    use
                                                                                                                                                    of
                                                                                                                                                    the
                                                                                                                                                    Services.
                                                                                                                                                    If
                                                                                                                                                    we
                                                                                                                                                    learn
                                                                                                                                                    that
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    from
                                                                                                                                                    users
                                                                                                                                                    less
                                                                                                                                                    than
                                                                                                                                                    18
                                                                                                                                                    years
                                                                                                                                                    of
                                                                                                                                                    age
                                                                                                                                                    has
                                                                                                                                                    been
                                                                                                                                                    collected,
                                                                                                                                                    we
                                                                                                                                                    will
                                                                                                                                                    deactivate
                                                                                                                                                    the
                                                                                                                                                    account
                                                                                                                                                    and
                                                                                                                                                    take
                                                                                                                                                    reasonable
                                                                                                                                                    measures
                                                                                                                                                    to
                                                                                                                                                    promptly
                                                                                                                                                    delete
                                                                                                                                                    such
                                                                                                                                                    data
                                                                                                                                                    from
                                                                                                                                                    our
                                                                                                                                                    records.
                                                                                                                                                    If
                                                                                                                                                    you
                                                                                                                                                    become
                                                                                                                                                    aware
                                                                                                                                                    of
                                                                                                                                                    any
                                                                                                                                                    data
                                                                                                                                                    we
                                                                                                                                                    may
                                                                                                                                                    have
                                                                                                                                                    collected
                                                                                                                                                    from
                                                                                                                                                    children
                                                                                                                                                    under
                                                                                                                                                    age
                                                                                                                                                    18,
                                                                                                                                                    please
                                                                                                                                                    contact
                                                                                                                                                    us
                                                                                                                                                    at{' '}
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                            fontSize: 15,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <span className="block-component" />
                                                                                                                                                            <span className="question">
                                                                                                                                                                info@passingpawsihe.com
                                                                                                                                                            </span>
                                                                                                                                                            <span className="else-block" />
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    .
                                                                                                                                                </span>
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span className="else-block">
                                                                                                                                                        <span className="block-component" />
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="privacyrights"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color: 'rgb(127, 127, 127)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color: 'rgb(0, 0, 0)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    7.
                                                                                                                                                                    WHAT
                                                                                                                                                                    ARE
                                                                                                                                                                    YOUR
                                                                                                                                                                    PRIVACY
                                                                                                                                                                    RIGHTS?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        {' '}
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <em>
                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                    </em>
                                                                                                                                                                </span>
                                                                                                                                                            </span>{' '}
                                                                                                                                                        </span>
                                                                                                                                                        You
                                                                                                                                                        may
                                                                                                                                                        review,
                                                                                                                                                        change,
                                                                                                                                                        or
                                                                                                                                                        terminate
                                                                                                                                                        your
                                                                                                                                                        account
                                                                                                                                                        at
                                                                                                                                                        any
                                                                                                                                                        time,
                                                                                                                                                        depending
                                                                                                                                                        on
                                                                                                                                                        your
                                                                                                                                                        country,
                                                                                                                                                        province,
                                                                                                                                                        or
                                                                                                                                                        state
                                                                                                                                                        of
                                                                                                                                                        residence.
                                                                                                                                                    </em>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span className="block-component">
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="withdrawconsent"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <u>
                                                                                                                                                            Withdrawing
                                                                                                                                                            your
                                                                                                                                                            consent:
                                                                                                                                                        </u>
                                                                                                                                                    </strong>{' '}
                                                                                                                                                    If
                                                                                                                                                    we
                                                                                                                                                    are
                                                                                                                                                    relying
                                                                                                                                                    on
                                                                                                                                                    your
                                                                                                                                                    consent
                                                                                                                                                    to
                                                                                                                                                    process
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information,
                                                                                                                                                    <span className="block-component" />{' '}
                                                                                                                                                    which
                                                                                                                                                    may
                                                                                                                                                    be
                                                                                                                                                    express
                                                                                                                                                    and/or
                                                                                                                                                    implied
                                                                                                                                                    consent
                                                                                                                                                    depending
                                                                                                                                                    on
                                                                                                                                                    the
                                                                                                                                                    applicable
                                                                                                                                                    law,
                                                                                                                                                    <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                    you
                                                                                                                                                    have
                                                                                                                                                    the
                                                                                                                                                    right
                                                                                                                                                    to
                                                                                                                                                    withdraw
                                                                                                                                                    your
                                                                                                                                                    consent
                                                                                                                                                    at
                                                                                                                                                    any
                                                                                                                                                    time.
                                                                                                                                                    You
                                                                                                                                                    can
                                                                                                                                                    withdraw
                                                                                                                                                    your
                                                                                                                                                    consent
                                                                                                                                                    at
                                                                                                                                                    any
                                                                                                                                                    time
                                                                                                                                                    by
                                                                                                                                                    contacting
                                                                                                                                                    us
                                                                                                                                                    by
                                                                                                                                                    using
                                                                                                                                                    the
                                                                                                                                                    contact
                                                                                                                                                    details
                                                                                                                                                    provided
                                                                                                                                                    in
                                                                                                                                                    the
                                                                                                                                                    section{' '}
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                    "
                                                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <a
                                                                                                                                            data-custom-class="link"
                                                                                                                                            href="#contact"
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(0, 58, 250)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color: 'rgb(0, 58, 250)',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        HOW
                                                                                                                                                        CAN
                                                                                                                                                        YOU
                                                                                                                                                        CONTACT
                                                                                                                                                        US
                                                                                                                                                        ABOUT
                                                                                                                                                        THIS
                                                                                                                                                        NOTICE?
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </a>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                    "
                                                                                                                                                    <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                    below
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                    .
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                However,
                                                                                                                                                please
                                                                                                                                                note
                                                                                                                                                that
                                                                                                                                                this
                                                                                                                                                will
                                                                                                                                                not
                                                                                                                                                affect
                                                                                                                                                the
                                                                                                                                                lawfulness
                                                                                                                                                of the
                                                                                                                                                processing
                                                                                                                                                before
                                                                                                                                                its
                                                                                                                                                withdrawal
                                                                                                                                                nor,
                                                                                                                                                <span className="block-component" />{' '}
                                                                                                                                                when
                                                                                                                                                applicable
                                                                                                                                                law
                                                                                                                                                allows,
                                                                                                                                                <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                will
                                                                                                                                                it
                                                                                                                                                affect
                                                                                                                                                the
                                                                                                                                                processing
                                                                                                                                                of
                                                                                                                                                your
                                                                                                                                                personal
                                                                                                                                                information
                                                                                                                                                conducted
                                                                                                                                                in
                                                                                                                                                reliance
                                                                                                                                                on
                                                                                                                                                lawful
                                                                                                                                                processing
                                                                                                                                                grounds
                                                                                                                                                other
                                                                                                                                                than
                                                                                                                                                consent.
                                                                                                                                                <span className="block-component" />
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                If you
                                                                                                                                                have
                                                                                                                                                questions
                                                                                                                                                or
                                                                                                                                                comments
                                                                                                                                                about
                                                                                                                                                your
                                                                                                                                                privacy
                                                                                                                                                rights,
                                                                                                                                                you
                                                                                                                                                may
                                                                                                                                                email
                                                                                                                                                us at{' '}
                                                                                                                                                <span className="question">
                                                                                                                                                    info@passingpawsihe.com
                                                                                                                                                </span>
                                                                                                                                                .
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span className="statement-end-if-in-editor">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="DNT"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color: 'rgb(127, 127, 127)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color: 'rgb(0, 0, 0)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    8.
                                                                                                                                                                    CONTROLS
                                                                                                                                                                    FOR
                                                                                                                                                                    DO-NOT-TRACK
                                                                                                                                                                    FEATURES
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    Most
                                                                                                                                                    web
                                                                                                                                                    browsers
                                                                                                                                                    and
                                                                                                                                                    some
                                                                                                                                                    mobile
                                                                                                                                                    operating
                                                                                                                                                    systems
                                                                                                                                                    and
                                                                                                                                                    mobile
                                                                                                                                                    applications
                                                                                                                                                    include
                                                                                                                                                    a
                                                                                                                                                    Do-Not-Track
                                                                                                                                                    (
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                    "DNT"
                                                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                                                    )
                                                                                                                                                    feature
                                                                                                                                                    or
                                                                                                                                                    setting
                                                                                                                                                    you
                                                                                                                                                    can
                                                                                                                                                    activate
                                                                                                                                                    to
                                                                                                                                                    signal
                                                                                                                                                    your
                                                                                                                                                    privacy
                                                                                                                                                    preference
                                                                                                                                                    not
                                                                                                                                                    to
                                                                                                                                                    have
                                                                                                                                                    data
                                                                                                                                                    about
                                                                                                                                                    your
                                                                                                                                                    online
                                                                                                                                                    browsing
                                                                                                                                                    activities
                                                                                                                                                    monitored
                                                                                                                                                    and
                                                                                                                                                    collected.
                                                                                                                                                    At
                                                                                                                                                    this
                                                                                                                                                    stage,
                                                                                                                                                    no
                                                                                                                                                    uniform
                                                                                                                                                    technology
                                                                                                                                                    standard
                                                                                                                                                    for{' '}
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                    recognizing
                                                                                                                                                    <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                    and
                                                                                                                                                    implementing
                                                                                                                                                    DNT
                                                                                                                                                    signals
                                                                                                                                                    has
                                                                                                                                                    been{' '}
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                    finalized
                                                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                                                    .
                                                                                                                                                    As
                                                                                                                                                    such,
                                                                                                                                                    we
                                                                                                                                                    do
                                                                                                                                                    not
                                                                                                                                                    currently
                                                                                                                                                    respond
                                                                                                                                                    to
                                                                                                                                                    DNT
                                                                                                                                                    browser
                                                                                                                                                    signals
                                                                                                                                                    or
                                                                                                                                                    any
                                                                                                                                                    other
                                                                                                                                                    mechanism
                                                                                                                                                    that
                                                                                                                                                    automatically
                                                                                                                                                    communicates
                                                                                                                                                    your
                                                                                                                                                    choice
                                                                                                                                                    not
                                                                                                                                                    to
                                                                                                                                                    be
                                                                                                                                                    tracked
                                                                                                                                                    online.
                                                                                                                                                    If
                                                                                                                                                    a
                                                                                                                                                    standard
                                                                                                                                                    for
                                                                                                                                                    online
                                                                                                                                                    tracking
                                                                                                                                                    is
                                                                                                                                                    adopted
                                                                                                                                                    that
                                                                                                                                                    we
                                                                                                                                                    must
                                                                                                                                                    follow
                                                                                                                                                    in
                                                                                                                                                    the
                                                                                                                                                    future,
                                                                                                                                                    we
                                                                                                                                                    will
                                                                                                                                                    inform
                                                                                                                                                    you
                                                                                                                                                    about
                                                                                                                                                    that
                                                                                                                                                    practice
                                                                                                                                                    in
                                                                                                                                                    a
                                                                                                                                                    revised
                                                                                                                                                    version
                                                                                                                                                    of
                                                                                                                                                    this
                                                                                                                                                    privacy
                                                                                                                                                    notice.
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            />
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <br />
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                California
                                                                                                                                                law
                                                                                                                                                requires
                                                                                                                                                us to
                                                                                                                                                let
                                                                                                                                                you
                                                                                                                                                know
                                                                                                                                                how we
                                                                                                                                                respond
                                                                                                                                                to web
                                                                                                                                                browser
                                                                                                                                                DNT
                                                                                                                                                signals.
                                                                                                                                                Because
                                                                                                                                                there
                                                                                                                                                currently
                                                                                                                                                is not
                                                                                                                                                an
                                                                                                                                                industry
                                                                                                                                                or
                                                                                                                                                legal
                                                                                                                                                standard
                                                                                                                                                for{' '}
                                                                                                                                                <span className="block-component" />
                                                                                                                                                recognizing
                                                                                                                                                <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                or{' '}
                                                                                                                                                <span className="block-component" />
                                                                                                                                                honoring
                                                                                                                                                <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                DNT
                                                                                                                                                signals,
                                                                                                                                                we do
                                                                                                                                                not
                                                                                                                                                respond
                                                                                                                                                to
                                                                                                                                                them
                                                                                                                                                at
                                                                                                                                                this
                                                                                                                                                time.
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span className="statement-end-if-in-editor">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            />
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="uslaws"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color: 'rgb(127, 127, 127)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color: 'rgb(0, 0, 0)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    9.
                                                                                                                                                                    DO
                                                                                                                                                                    UNITED
                                                                                                                                                                    STATES
                                                                                                                                                                    RESIDENTS
                                                                                                                                                                    HAVE
                                                                                                                                                                    SPECIFIC
                                                                                                                                                                    PRIVACY
                                                                                                                                                                    RIGHTS?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:{' '}
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        If
                                                                                                                                                        you
                                                                                                                                                        are
                                                                                                                                                        a
                                                                                                                                                        resident
                                                                                                                                                        of
                                                                                                                                                        <span className="block-component" />{' '}
                                                                                                                                                        California,
                                                                                                                                                        Colorado,
                                                                                                                                                        Connecticut,
                                                                                                                                                        Delaware,
                                                                                                                                                        Florida,
                                                                                                                                                        Indiana,
                                                                                                                                                        Iowa,
                                                                                                                                                        Kentucky,
                                                                                                                                                        Montana,
                                                                                                                                                        New
                                                                                                                                                        Hampshire,
                                                                                                                                                        New
                                                                                                                                                        Jersey,
                                                                                                                                                        Oregon,
                                                                                                                                                        Tennessee,
                                                                                                                                                        Texas,
                                                                                                                                                        Utah,
                                                                                                                                                        or
                                                                                                                                                        Virginia
                                                                                                                                                        <span className="else-block" />

                                                                                                                                                        ,
                                                                                                                                                        you
                                                                                                                                                        may
                                                                                                                                                        have
                                                                                                                                                        the
                                                                                                                                                        right
                                                                                                                                                        to
                                                                                                                                                        request
                                                                                                                                                        access
                                                                                                                                                        to
                                                                                                                                                        and
                                                                                                                                                        receive
                                                                                                                                                        details
                                                                                                                                                        about
                                                                                                                                                        the
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        we
                                                                                                                                                        maintain
                                                                                                                                                        about
                                                                                                                                                        you
                                                                                                                                                        and
                                                                                                                                                        how
                                                                                                                                                        we
                                                                                                                                                        have
                                                                                                                                                        processed
                                                                                                                                                        it,
                                                                                                                                                        correct
                                                                                                                                                        inaccuracies,
                                                                                                                                                        get
                                                                                                                                                        a
                                                                                                                                                        copy
                                                                                                                                                        of,
                                                                                                                                                        or
                                                                                                                                                        delete
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information.
                                                                                                                                                        You
                                                                                                                                                        may
                                                                                                                                                        also
                                                                                                                                                        have
                                                                                                                                                        the
                                                                                                                                                        right
                                                                                                                                                        to
                                                                                                                                                        withdraw
                                                                                                                                                        your
                                                                                                                                                        consent
                                                                                                                                                        to
                                                                                                                                                        our
                                                                                                                                                        processing
                                                                                                                                                        of
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information.
                                                                                                                                                        These
                                                                                                                                                        rights
                                                                                                                                                        may
                                                                                                                                                        be
                                                                                                                                                        limited
                                                                                                                                                        in
                                                                                                                                                        some
                                                                                                                                                        circumstances
                                                                                                                                                        by
                                                                                                                                                        applicable
                                                                                                                                                        law.
                                                                                                                                                        More
                                                                                                                                                        information
                                                                                                                                                        is
                                                                                                                                                        provided
                                                                                                                                                        below.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <strong>
                                                                                                                                                    <span data-custom-class="heading_2">
                                                                                                                                                        Categories
                                                                                                                                                        of
                                                                                                                                                        Personal
                                                                                                                                                        Information
                                                                                                                                                        We
                                                                                                                                                        Collect
                                                                                                                                                    </span>
                                                                                                                                                </strong>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We
                                                                                                                                                    have
                                                                                                                                                    collected
                                                                                                                                                    the
                                                                                                                                                    following
                                                                                                                                                    categories
                                                                                                                                                    of
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    in
                                                                                                                                                    the
                                                                                                                                                    past
                                                                                                                                                    twelve
                                                                                                                                                    (12)
                                                                                                                                                    months:
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <table
                                                                                                                                        style={{
                                                                                                                                            width: '100%',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <strong>
                                                                                                                                                                    Category
                                                                                                                                                                </strong>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <strong>
                                                                                                                                                                    Examples
                                                                                                                                                                </strong>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <strong>
                                                                                                                                                                    Collected
                                                                                                                                                                </strong>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    A.
                                                                                                                                                                    Identifiers
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Contact
                                                                                                                                                                    details,
                                                                                                                                                                    such
                                                                                                                                                                    as
                                                                                                                                                                    real
                                                                                                                                                                    name,
                                                                                                                                                                    alias,
                                                                                                                                                                    postal
                                                                                                                                                                    address,
                                                                                                                                                                    telephone
                                                                                                                                                                    or
                                                                                                                                                                    mobile
                                                                                                                                                                    contact
                                                                                                                                                                    number,
                                                                                                                                                                    unique
                                                                                                                                                                    personal
                                                                                                                                                                    identifier,
                                                                                                                                                                    online
                                                                                                                                                                    identifier,
                                                                                                                                                                    Internet
                                                                                                                                                                    Protocol
                                                                                                                                                                    address,
                                                                                                                                                                    email
                                                                                                                                                                    address,
                                                                                                                                                                    and
                                                                                                                                                                    account
                                                                                                                                                                    name
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        verticalAlign:
                                                                                                                                                            'middle',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component">
                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                    </span>
                                                                                                                                                                    YES
                                                                                                                                                                    <span className="else-block">
                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span className="block-component" />
                                                                                                                                    </div>
                                                                                                                                    <table
                                                                                                                                        style={{
                                                                                                                                            width: '100%',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    B.
                                                                                                                                                                    Personal
                                                                                                                                                                    information
                                                                                                                                                                    as
                                                                                                                                                                    defined
                                                                                                                                                                    in
                                                                                                                                                                    the
                                                                                                                                                                    California
                                                                                                                                                                    Customer
                                                                                                                                                                    Records
                                                                                                                                                                    statute
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Name,
                                                                                                                                                                    contact
                                                                                                                                                                    information,
                                                                                                                                                                    education,
                                                                                                                                                                    employment,
                                                                                                                                                                    employment
                                                                                                                                                                    history,
                                                                                                                                                                    and
                                                                                                                                                                    financial
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="forloop-component">
                                                                                                                                                                        <span className="block-component">
                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                            YES
                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span className="block-component" />
                                                                                                                                    </div>
                                                                                                                                    <table
                                                                                                                                        style={{
                                                                                                                                            width: '100%',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                    C
                                                                                                                                                                    <span className="else-block" />

                                                                                                                                                                    .
                                                                                                                                                                    Protected
                                                                                                                                                                    classification
                                                                                                                                                                    characteristics
                                                                                                                                                                    under
                                                                                                                                                                    state
                                                                                                                                                                    or
                                                                                                                                                                    federal
                                                                                                                                                                    law
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Gender,
                                                                                                                                                                    age,
                                                                                                                                                                    date
                                                                                                                                                                    of
                                                                                                                                                                    birth,
                                                                                                                                                                    race
                                                                                                                                                                    and
                                                                                                                                                                    ethnicity,
                                                                                                                                                                    national
                                                                                                                                                                    origin,
                                                                                                                                                                    marital
                                                                                                                                                                    status,
                                                                                                                                                                    and
                                                                                                                                                                    other
                                                                                                                                                                    demographic
                                                                                                                                                                    data
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="forloop-component">
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                NO
                                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                    D
                                                                                                                                                                    <span className="else-block" />

                                                                                                                                                                    .
                                                                                                                                                                    Commercial
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Transaction
                                                                                                                                                                    information,
                                                                                                                                                                    purchase
                                                                                                                                                                    history,
                                                                                                                                                                    financial
                                                                                                                                                                    details,
                                                                                                                                                                    and
                                                                                                                                                                    payment
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="forloop-component">
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                NO
                                                                                                                                                                <span className="statement-end-if-in-editor">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                    E
                                                                                                                                                                    <span className="else-block" />

                                                                                                                                                                    .
                                                                                                                                                                    Biometric
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Fingerprints
                                                                                                                                                                    and
                                                                                                                                                                    voiceprints
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="forloop-component">
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span className="block-component">
                                                                                                                                                                    <span className="block-component">
                                                                                                                                                                        NO
                                                                                                                                                                    </span>
                                                                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                    F
                                                                                                                                                                    <span className="else-block" />

                                                                                                                                                                    .
                                                                                                                                                                    Internet
                                                                                                                                                                    or
                                                                                                                                                                    other
                                                                                                                                                                    similar
                                                                                                                                                                    network
                                                                                                                                                                    activity
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Browsing
                                                                                                                                                                    history,
                                                                                                                                                                    search
                                                                                                                                                                    history,
                                                                                                                                                                    online{' '}
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                    behavior
                                                                                                                                                                    <span className="statement-end-if-in-editor" />

                                                                                                                                                                    ,
                                                                                                                                                                    interest
                                                                                                                                                                    data,
                                                                                                                                                                    and
                                                                                                                                                                    interactions
                                                                                                                                                                    with
                                                                                                                                                                    our
                                                                                                                                                                    and
                                                                                                                                                                    other
                                                                                                                                                                    websites,
                                                                                                                                                                    applications,
                                                                                                                                                                    systems,
                                                                                                                                                                    and
                                                                                                                                                                    advertisements
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="forloop-component">
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                NO
                                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                    G
                                                                                                                                                                    <span className="else-block" />

                                                                                                                                                                    .
                                                                                                                                                                    Geolocation
                                                                                                                                                                    data
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Device
                                                                                                                                                                    location
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="forloop-component">
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                NO
                                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                    H
                                                                                                                                                                    <span className="else-block" />

                                                                                                                                                                    .
                                                                                                                                                                    Audio,
                                                                                                                                                                    electronic,
                                                                                                                                                                    sensory,
                                                                                                                                                                    or
                                                                                                                                                                    similar
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Images
                                                                                                                                                                    and
                                                                                                                                                                    audio,
                                                                                                                                                                    video
                                                                                                                                                                    or
                                                                                                                                                                    call
                                                                                                                                                                    recordings
                                                                                                                                                                    created
                                                                                                                                                                    in
                                                                                                                                                                    connection
                                                                                                                                                                    with
                                                                                                                                                                    our
                                                                                                                                                                    business
                                                                                                                                                                    activities
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="forloop-component">
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                NO
                                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                    I
                                                                                                                                                                    <span className="else-block" />

                                                                                                                                                                    .
                                                                                                                                                                    Professional
                                                                                                                                                                    or
                                                                                                                                                                    employment-related
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Business
                                                                                                                                                                    contact
                                                                                                                                                                    details
                                                                                                                                                                    in
                                                                                                                                                                    order
                                                                                                                                                                    to
                                                                                                                                                                    provide
                                                                                                                                                                    you
                                                                                                                                                                    our
                                                                                                                                                                    Services
                                                                                                                                                                    at
                                                                                                                                                                    a
                                                                                                                                                                    business
                                                                                                                                                                    level
                                                                                                                                                                    or
                                                                                                                                                                    job
                                                                                                                                                                    title,
                                                                                                                                                                    work
                                                                                                                                                                    history,
                                                                                                                                                                    and
                                                                                                                                                                    professional
                                                                                                                                                                    qualifications
                                                                                                                                                                    if
                                                                                                                                                                    you
                                                                                                                                                                    apply
                                                                                                                                                                    for
                                                                                                                                                                    a
                                                                                                                                                                    job
                                                                                                                                                                    with
                                                                                                                                                                    us
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="forloop-component">
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                NO
                                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                    J
                                                                                                                                                                    <span className="else-block" />

                                                                                                                                                                    .
                                                                                                                                                                    Education
                                                                                                                                                                    Information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Student
                                                                                                                                                                    records
                                                                                                                                                                    and
                                                                                                                                                                    directory
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="forloop-component">
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                NO
                                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderWidth: 1,
                                                                                                                                                        borderColor:
                                                                                                                                                            'black',
                                                                                                                                                        borderStyle:
                                                                                                                                                            'solid',
                                                                                                                                                        width: '33.8274%',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                    K
                                                                                                                                                                    <span className="else-block" />

                                                                                                                                                                    .
                                                                                                                                                                    Inferences
                                                                                                                                                                    drawn
                                                                                                                                                                    from
                                                                                                                                                                    collected
                                                                                                                                                                    personal
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderBottom:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        width: '51.4385%',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Inferences
                                                                                                                                                                    drawn
                                                                                                                                                                    from
                                                                                                                                                                    any
                                                                                                                                                                    of
                                                                                                                                                                    the
                                                                                                                                                                    collected
                                                                                                                                                                    personal
                                                                                                                                                                    information
                                                                                                                                                                    listed
                                                                                                                                                                    above
                                                                                                                                                                    to
                                                                                                                                                                    create
                                                                                                                                                                    a
                                                                                                                                                                    profile
                                                                                                                                                                    or
                                                                                                                                                                    summary
                                                                                                                                                                    about,
                                                                                                                                                                    for
                                                                                                                                                                    example,
                                                                                                                                                                    an
                                                                                                                                                                    individual
                                                                                                                                                                    s
                                                                                                                                                                    preferences
                                                                                                                                                                    and
                                                                                                                                                                    characteristics
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        textAlign:
                                                                                                                                                            'center',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderBottom:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderTop:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        width: '14.9084%',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <span className="block-component" />
                                                                                                                                                            NO
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderLeft:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderBottom:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        lineHeight:
                                                                                                                                                            '1.5',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        <span className="block-component" />
                                                                                                                                                        L
                                                                                                                                                        <span className="else-block" />

                                                                                                                                                        .
                                                                                                                                                        Sensitive
                                                                                                                                                        personal
                                                                                                                                                        Information
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderBottom:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        lineHeight:
                                                                                                                                                            '1.5',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span className="block-component">
                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderRight:
                                                                                                                                                            '1px solid black',
                                                                                                                                                        borderBottom:
                                                                                                                                                            '1px solid black',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        data-empty="true"
                                                                                                                                                        style={{
                                                                                                                                                            textAlign:
                                                                                                                                                                'center',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        data-empty="true"
                                                                                                                                                        style={{
                                                                                                                                                            textAlign:
                                                                                                                                                                'center',
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span className="block-component">
                                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                                        </span>
                                                                                                                                                        NO
                                                                                                                                                        <span className="statement-end-if-in-editor">
                                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        data-empty="true"
                                                                                                                                                        style={{
                                                                                                                                                            textAlign:
                                                                                                                                                                'center',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            />
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                We may
                                                                                                                                                also
                                                                                                                                                collect
                                                                                                                                                other
                                                                                                                                                personal
                                                                                                                                                information
                                                                                                                                                outside
                                                                                                                                                of
                                                                                                                                                these
                                                                                                                                                categories
                                                                                                                                                through
                                                                                                                                                instances
                                                                                                                                                where
                                                                                                                                                you
                                                                                                                                                interact
                                                                                                                                                with
                                                                                                                                                us in
                                                                                                                                                person,
                                                                                                                                                online,
                                                                                                                                                or by
                                                                                                                                                phone
                                                                                                                                                or
                                                                                                                                                mail
                                                                                                                                                in the
                                                                                                                                                context
                                                                                                                                                of:
                                                                                                                                            </span>
                                                                                                                                            <span className="block-component" />
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <ul>
                                                                                                                                        <li
                                                                                                                                            data-custom-class="body_text"
                                                                                                                                            style={{
                                                                                                                                                lineHeight:
                                                                                                                                                    '1.5',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                Receiving
                                                                                                                                                help
                                                                                                                                                through
                                                                                                                                                our
                                                                                                                                                customer
                                                                                                                                                support
                                                                                                                                                channels;
                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                            </span>
                                                                                                                                        </li>
                                                                                                                                    </ul>
                                                                                                                                    <div>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span className="block-component" />
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <ul>
                                                                                                                                        <li data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                Participation
                                                                                                                                                in
                                                                                                                                                customer
                                                                                                                                                surveys
                                                                                                                                                or
                                                                                                                                                contests;
                                                                                                                                                and
                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                            </span>
                                                                                                                                        </li>
                                                                                                                                    </ul>
                                                                                                                                    <div>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span className="block-component" />
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <ul>
                                                                                                                                        <li data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                Facilitation
                                                                                                                                                in the
                                                                                                                                                delivery
                                                                                                                                                of our
                                                                                                                                                Services
                                                                                                                                                and to
                                                                                                                                                respond
                                                                                                                                                to
                                                                                                                                                your
                                                                                                                                                inquiries.
                                                                                                                                            </span>
                                                                                                                                            <span className="statement-end-if-in-editor">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                    }}
                                                                                                                                                />
                                                                                                                                            </span>
                                                                                                                                        </li>
                                                                                                                                    </ul>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span className="block-component">
                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                        </span>
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            We will
                                                                                                                                            use and
                                                                                                                                            retain the
                                                                                                                                            collected
                                                                                                                                            personal
                                                                                                                                            information
                                                                                                                                            as needed
                                                                                                                                            to provide
                                                                                                                                            the
                                                                                                                                            Services
                                                                                                                                            or for:
                                                                                                                                            <span className="block-component" />
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <ul>
                                                                                                                                        <li
                                                                                                                                            data-custom-class="body_text"
                                                                                                                                            style={{
                                                                                                                                                lineHeight:
                                                                                                                                                    '1.5',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                Category
                                                                                                                                                A -{' '}
                                                                                                                                                <span className="question">
                                                                                                                                                    5
                                                                                                                                                    Years
                                                                                                                                                </span>
                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                            </span>
                                                                                                                                        </li>
                                                                                                                                    </ul>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <span className="block-component">
                                                                                                                                                <span className="block-component" />
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <ul>
                                                                                                                                        <li
                                                                                                                                            data-custom-class="body_text"
                                                                                                                                            style={{
                                                                                                                                                lineHeight:
                                                                                                                                                    '1.5',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                Category
                                                                                                                                                B -{' '}
                                                                                                                                                <span className="question">
                                                                                                                                                    5
                                                                                                                                                    Years
                                                                                                                                                </span>
                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                            </span>
                                                                                                                                        </li>
                                                                                                                                    </ul>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                '1.5',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <span className="block-component" />
                                                                                                                                        </span>
                                                                                                                                        <div
                                                                                                                                            style={{
                                                                                                                                                lineHeight:
                                                                                                                                                    '1.5',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                <span className="block-component" />
                                                                                                                                            </span>
                                                                                                                                            <div
                                                                                                                                                style={{
                                                                                                                                                    lineHeight:
                                                                                                                                                        '1.5',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span className="block-component" />
                                                                                                                                                </span>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            '1.5',
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        <span className="block-component" />
                                                                                                                                                    </span>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                '1.5',
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <span className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                        <div
                                                                                                                                                            style={{
                                                                                                                                                                lineHeight:
                                                                                                                                                                    '1.5',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                            <div
                                                                                                                                                                style={{
                                                                                                                                                                    lineHeight:
                                                                                                                                                                        '1.5',
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                </span>
                                                                                                                                                                <div
                                                                                                                                                                    style={{
                                                                                                                                                                        lineHeight:
                                                                                                                                                                            '1.5',
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                    </span>
                                                                                                                                                                    <div
                                                                                                                                                                        style={{
                                                                                                                                                                            lineHeight:
                                                                                                                                                                                '1.5',
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                        </span>
                                                                                                                                                                        <div
                                                                                                                                                                            style={{
                                                                                                                                                                                lineHeight:
                                                                                                                                                                                    '1.5',
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                            </span>
                                                                                                                                                                            <span className="statement-end-if-in-editor">
                                                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                                                            </span>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <strong>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="heading_2">
                                                                                                                                                                                            Sources
                                                                                                                                                                                            of
                                                                                                                                                                                            Personal
                                                                                                                                                                                            Information
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </strong>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        Learn
                                                                                                                                                                                        more
                                                                                                                                                                                        about
                                                                                                                                                                                        the
                                                                                                                                                                                        sources
                                                                                                                                                                                        of
                                                                                                                                                                                        personal
                                                                                                                                                                                        information
                                                                                                                                                                                        we
                                                                                                                                                                                        collect
                                                                                                                                                                                        in{' '}
                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                        "
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                    <a
                                                                                                                                                                                        data-custom-class="link"
                                                                                                                                                                                        href="#infocollect"
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color: 'rgb (0, 58, 250)',
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            WHAT
                                                                                                                                                                                            INFORMATION
                                                                                                                                                                                            DO
                                                                                                                                                                                            WE
                                                                                                                                                                                            COLLECT?
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </a>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        <span className="block-component" />

                                                                                                                                                                                        "
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span className="statement-end-if-in-editor">
                                                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <strong>
                                                                                                                                                                                            <span data-custom-class="heading_2">
                                                                                                                                                                                                How
                                                                                                                                                                                                We
                                                                                                                                                                                                Use
                                                                                                                                                                                                and
                                                                                                                                                                                                Share
                                                                                                                                                                                                Personal
                                                                                                                                                                                                Information
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </strong>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    Learn
                                                                                                                                                                                    about
                                                                                                                                                                                    how
                                                                                                                                                                                    we
                                                                                                                                                                                    use
                                                                                                                                                                                    your
                                                                                                                                                                                    personal
                                                                                                                                                                                    information
                                                                                                                                                                                    in
                                                                                                                                                                                    the
                                                                                                                                                                                    section,{' '}
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                    "
                                                                                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                                                                                </span>
                                                                                                                                                                                <a
                                                                                                                                                                                    data-custom-class="link"
                                                                                                                                                                                    href="#infouse"
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color: 'rgb(0, 58, 250)',
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        HOW
                                                                                                                                                                                        DO
                                                                                                                                                                                        WE
                                                                                                                                                                                        PROCESS
                                                                                                                                                                                        YOUR
                                                                                                                                                                                        INFORMATION?
                                                                                                                                                                                    </span>
                                                                                                                                                                                </a>
                                                                                                                                                                                <span
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span className="block-component" />

                                                                                                                                                                                    "
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="statement-end-if-in-editor">
                                                                                                                                                                                    <span
                                                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <strong>
                                                                                                                                                                                                Will
                                                                                                                                                                                                your
                                                                                                                                                                                                information
                                                                                                                                                                                                be
                                                                                                                                                                                                shared
                                                                                                                                                                                                with
                                                                                                                                                                                                anyone
                                                                                                                                                                                                else?
                                                                                                                                                                                            </strong>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            We
                                                                                                                                                                                            may
                                                                                                                                                                                            disclose
                                                                                                                                                                                            your
                                                                                                                                                                                            personal
                                                                                                                                                                                            information
                                                                                                                                                                                            with
                                                                                                                                                                                            our
                                                                                                                                                                                            service
                                                                                                                                                                                            providers
                                                                                                                                                                                            pursuant
                                                                                                                                                                                            to
                                                                                                                                                                                            a
                                                                                                                                                                                            written
                                                                                                                                                                                            contract
                                                                                                                                                                                            between
                                                                                                                                                                                            us
                                                                                                                                                                                            and
                                                                                                                                                                                            each
                                                                                                                                                                                            service
                                                                                                                                                                                            provider.
                                                                                                                                                                                            Learn
                                                                                                                                                                                            more
                                                                                                                                                                                            about
                                                                                                                                                                                            how
                                                                                                                                                                                            we
                                                                                                                                                                                            disclose
                                                                                                                                                                                            personal
                                                                                                                                                                                            information
                                                                                                                                                                                            to
                                                                                                                                                                                            in
                                                                                                                                                                                            the
                                                                                                                                                                                            section,{' '}
                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                            "
                                                                                                                                                                                            <span className="statement-end-if-in-editor" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <a
                                                                                                                                                                                    data-custom-class="link"
                                                                                                                                                                                    href="#whoshare"
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(0, 58, 250)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color: 'rgb(0, 58, 250)',
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            WHEN
                                                                                                                                                                                            AND
                                                                                                                                                                                            WITH
                                                                                                                                                                                            WHOM
                                                                                                                                                                                            DO
                                                                                                                                                                                            WE
                                                                                                                                                                                            SHARE
                                                                                                                                                                                            YOUR
                                                                                                                                                                                            PERSONAL
                                                                                                                                                                                            INFORMATION?
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </a>
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                            "
                                                                                                                                                                                            <span className="statement-end-if-in-editor" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            We
                                                                                                                                                                                            may
                                                                                                                                                                                            use
                                                                                                                                                                                            your
                                                                                                                                                                                            personal
                                                                                                                                                                                            information
                                                                                                                                                                                            for
                                                                                                                                                                                            our
                                                                                                                                                                                            own
                                                                                                                                                                                            business
                                                                                                                                                                                            purposes,
                                                                                                                                                                                            such
                                                                                                                                                                                            as
                                                                                                                                                                                            for
                                                                                                                                                                                            undertaking
                                                                                                                                                                                            internal
                                                                                                                                                                                            research
                                                                                                                                                                                            for
                                                                                                                                                                                            technological
                                                                                                                                                                                            development
                                                                                                                                                                                            and
                                                                                                                                                                                            demonstration.
                                                                                                                                                                                            This
                                                                                                                                                                                            is
                                                                                                                                                                                            not
                                                                                                                                                                                            considered
                                                                                                                                                                                            to
                                                                                                                                                                                            be{' '}
                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                            "selling"
                                                                                                                                                                                            <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                                                            of
                                                                                                                                                                                            your
                                                                                                                                                                                            personal
                                                                                                                                                                                            information.
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            We
                                                                                                                                                                                            have
                                                                                                                                                                                            not
                                                                                                                                                                                            disclosed,
                                                                                                                                                                                            sold,
                                                                                                                                                                                            or
                                                                                                                                                                                            shared
                                                                                                                                                                                            any
                                                                                                                                                                                            personal
                                                                                                                                                                                            information
                                                                                                                                                                                            to
                                                                                                                                                                                            third
                                                                                                                                                                                            parties
                                                                                                                                                                                            for
                                                                                                                                                                                            a
                                                                                                                                                                                            business
                                                                                                                                                                                            or
                                                                                                                                                                                            commercial
                                                                                                                                                                                            purpose
                                                                                                                                                                                            in
                                                                                                                                                                                            the
                                                                                                                                                                                            preceding
                                                                                                                                                                                            twelve
                                                                                                                                                                                            (12)
                                                                                                                                                                                            months.
                                                                                                                                                                                            We
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                {' '}
                                                                                                                                                                                            </span>
                                                                                                                                                                                            will
                                                                                                                                                                                            not
                                                                                                                                                                                            sell
                                                                                                                                                                                            or
                                                                                                                                                                                            share
                                                                                                                                                                                            personal
                                                                                                                                                                                            information
                                                                                                                                                                                            in
                                                                                                                                                                                            the
                                                                                                                                                                                            future
                                                                                                                                                                                            belonging
                                                                                                                                                                                            to
                                                                                                                                                                                            website
                                                                                                                                                                                            visitors,
                                                                                                                                                                                            users,
                                                                                                                                                                                            and
                                                                                                                                                                                            other
                                                                                                                                                                                            consumers.
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color: 'rgb(0, 0, 0)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <strong>
                                                                                                                                                                                        <span data-custom-class="heading_2">
                                                                                                                                                                                            Your
                                                                                                                                                                                            Rights
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </strong>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        You
                                                                                                                                                                                        have
                                                                                                                                                                                        rights
                                                                                                                                                                                        under
                                                                                                                                                                                        certain
                                                                                                                                                                                        US
                                                                                                                                                                                        state
                                                                                                                                                                                        data
                                                                                                                                                                                        protection
                                                                                                                                                                                        laws.
                                                                                                                                                                                        However,
                                                                                                                                                                                        these
                                                                                                                                                                                        rights
                                                                                                                                                                                        are
                                                                                                                                                                                        not
                                                                                                                                                                                        absolute,
                                                                                                                                                                                        and
                                                                                                                                                                                        in
                                                                                                                                                                                        certain
                                                                                                                                                                                        cases,
                                                                                                                                                                                        we
                                                                                                                                                                                        may
                                                                                                                                                                                        decline
                                                                                                                                                                                        your
                                                                                                                                                                                        request
                                                                                                                                                                                        as
                                                                                                                                                                                        permitted
                                                                                                                                                                                        by
                                                                                                                                                                                        law.
                                                                                                                                                                                        These
                                                                                                                                                                                        rights
                                                                                                                                                                                        include:
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <strong>
                                                                                                                                                                                            Right
                                                                                                                                                                                            to
                                                                                                                                                                                            know
                                                                                                                                                                                        </strong>{' '}
                                                                                                                                                                                        whether
                                                                                                                                                                                        or
                                                                                                                                                                                        not
                                                                                                                                                                                        we
                                                                                                                                                                                        are
                                                                                                                                                                                        processing
                                                                                                                                                                                        your
                                                                                                                                                                                        personal
                                                                                                                                                                                        data
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <strong>
                                                                                                                                                                                            Right
                                                                                                                                                                                            to
                                                                                                                                                                                            access{' '}
                                                                                                                                                                                        </strong>
                                                                                                                                                                                        your
                                                                                                                                                                                        personal
                                                                                                                                                                                        data
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <strong>
                                                                                                                                                                                            Right
                                                                                                                                                                                            to
                                                                                                                                                                                            correct{' '}
                                                                                                                                                                                        </strong>
                                                                                                                                                                                        inaccuracies
                                                                                                                                                                                        in
                                                                                                                                                                                        your
                                                                                                                                                                                        personal
                                                                                                                                                                                        data
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <strong>
                                                                                                                                                                                            Right
                                                                                                                                                                                            to
                                                                                                                                                                                            request
                                                                                                                                                                                        </strong>{' '}
                                                                                                                                                                                        the
                                                                                                                                                                                        deletion
                                                                                                                                                                                        of
                                                                                                                                                                                        your
                                                                                                                                                                                        personal
                                                                                                                                                                                        data
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <strong>
                                                                                                                                                                                            Right
                                                                                                                                                                                            to
                                                                                                                                                                                            obtain
                                                                                                                                                                                            a
                                                                                                                                                                                            copy{' '}
                                                                                                                                                                                        </strong>
                                                                                                                                                                                        of
                                                                                                                                                                                        the
                                                                                                                                                                                        personal
                                                                                                                                                                                        data
                                                                                                                                                                                        you
                                                                                                                                                                                        previously
                                                                                                                                                                                        shared
                                                                                                                                                                                        with
                                                                                                                                                                                        us
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <strong>
                                                                                                                                                                                            Right
                                                                                                                                                                                            to
                                                                                                                                                                                            non-discrimination
                                                                                                                                                                                        </strong>{' '}
                                                                                                                                                                                        for
                                                                                                                                                                                        exercising
                                                                                                                                                                                        your
                                                                                                                                                                                        rights
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <strong>
                                                                                                                                                                                            Right
                                                                                                                                                                                            to
                                                                                                                                                                                            opt
                                                                                                                                                                                            out
                                                                                                                                                                                        </strong>{' '}
                                                                                                                                                                                        of
                                                                                                                                                                                        the
                                                                                                                                                                                        processing
                                                                                                                                                                                        of
                                                                                                                                                                                        your
                                                                                                                                                                                        personal
                                                                                                                                                                                        data
                                                                                                                                                                                        if
                                                                                                                                                                                        it
                                                                                                                                                                                        is
                                                                                                                                                                                        used
                                                                                                                                                                                        for
                                                                                                                                                                                        targeted
                                                                                                                                                                                        advertising
                                                                                                                                                                                        <span className="block-component" />{' '}
                                                                                                                                                                                        (or
                                                                                                                                                                                        sharing
                                                                                                                                                                                        as
                                                                                                                                                                                        defined
                                                                                                                                                                                        under
                                                                                                                                                                                        California
                                                                                                                                                                                        s
                                                                                                                                                                                        privacy
                                                                                                                                                                                        law)
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                        ,
                                                                                                                                                                                        the
                                                                                                                                                                                        sale
                                                                                                                                                                                        of
                                                                                                                                                                                        personal
                                                                                                                                                                                        data,
                                                                                                                                                                                        or
                                                                                                                                                                                        profiling
                                                                                                                                                                                        in
                                                                                                                                                                                        furtherance
                                                                                                                                                                                        of
                                                                                                                                                                                        decisions
                                                                                                                                                                                        that
                                                                                                                                                                                        produce
                                                                                                                                                                                        legal
                                                                                                                                                                                        or
                                                                                                                                                                                        similarly
                                                                                                                                                                                        significant
                                                                                                                                                                                        effects
                                                                                                                                                                                        (
                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                        "profiling"
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                        )
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        Depending
                                                                                                                                                                                        upon
                                                                                                                                                                                        the
                                                                                                                                                                                        state
                                                                                                                                                                                        where
                                                                                                                                                                                        you
                                                                                                                                                                                        live,
                                                                                                                                                                                        you
                                                                                                                                                                                        may
                                                                                                                                                                                        also
                                                                                                                                                                                        have
                                                                                                                                                                                        the
                                                                                                                                                                                        following
                                                                                                                                                                                        rights:
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        Right
                                                                                                                                                                                        to
                                                                                                                                                                                        obtain
                                                                                                                                                                                        a
                                                                                                                                                                                        list
                                                                                                                                                                                        of
                                                                                                                                                                                        the
                                                                                                                                                                                        categories
                                                                                                                                                                                        of
                                                                                                                                                                                        third
                                                                                                                                                                                        parties
                                                                                                                                                                                        to
                                                                                                                                                                                        which
                                                                                                                                                                                        we
                                                                                                                                                                                        have
                                                                                                                                                                                        disclosed
                                                                                                                                                                                        personal
                                                                                                                                                                                        data
                                                                                                                                                                                        (as
                                                                                                                                                                                        permitted
                                                                                                                                                                                        by
                                                                                                                                                                                        applicable
                                                                                                                                                                                        law,
                                                                                                                                                                                        including
                                                                                                                                                                                        <span className="block-component" />{' '}
                                                                                                                                                                                        California's
                                                                                                                                                                                        and
                                                                                                                                                                                        Delaware's
                                                                                                                                                                                        <span className="else-block" />{' '}
                                                                                                                                                                                        privacy
                                                                                                                                                                                        law)
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        Right
                                                                                                                                                                                        to
                                                                                                                                                                                        obtain
                                                                                                                                                                                        a
                                                                                                                                                                                        list
                                                                                                                                                                                        of
                                                                                                                                                                                        specific
                                                                                                                                                                                        third
                                                                                                                                                                                        parties
                                                                                                                                                                                        to
                                                                                                                                                                                        which
                                                                                                                                                                                        we
                                                                                                                                                                                        have
                                                                                                                                                                                        disclosed
                                                                                                                                                                                        personal
                                                                                                                                                                                        data
                                                                                                                                                                                        (as
                                                                                                                                                                                        permitted
                                                                                                                                                                                        by
                                                                                                                                                                                        applicable
                                                                                                                                                                                        law,
                                                                                                                                                                                        including
                                                                                                                                                                                        Oregon
                                                                                                                                                                                        s
                                                                                                                                                                                        privacy
                                                                                                                                                                                        law)
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span className="statement-end-if-in-editor">
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                            }}
                                                                                                                                                                                        />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        Right
                                                                                                                                                                                        to
                                                                                                                                                                                        limit
                                                                                                                                                                                        use
                                                                                                                                                                                        and
                                                                                                                                                                                        disclosure
                                                                                                                                                                                        of
                                                                                                                                                                                        sensitive
                                                                                                                                                                                        personal
                                                                                                                                                                                        data
                                                                                                                                                                                        (as
                                                                                                                                                                                        permitted
                                                                                                                                                                                        by
                                                                                                                                                                                        applicable
                                                                                                                                                                                        law,
                                                                                                                                                                                        including
                                                                                                                                                                                        California
                                                                                                                                                                                        s
                                                                                                                                                                                        privacy
                                                                                                                                                                                        law)
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span className="statement-end-if-in-editor">
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                            }}
                                                                                                                                                                                        />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <ul>
                                                                                                                                                                                <li
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            '1.5',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        Right
                                                                                                                                                                                        to
                                                                                                                                                                                        opt
                                                                                                                                                                                        out
                                                                                                                                                                                        of
                                                                                                                                                                                        the
                                                                                                                                                                                        collection
                                                                                                                                                                                        of
                                                                                                                                                                                        sensitive
                                                                                                                                                                                        data
                                                                                                                                                                                        and
                                                                                                                                                                                        personal
                                                                                                                                                                                        data
                                                                                                                                                                                        collected
                                                                                                                                                                                        through
                                                                                                                                                                                        the
                                                                                                                                                                                        operation
                                                                                                                                                                                        of
                                                                                                                                                                                        a
                                                                                                                                                                                        voice
                                                                                                                                                                                        or
                                                                                                                                                                                        facial
                                                                                                                                                                                        recognition
                                                                                                                                                                                        feature
                                                                                                                                                                                        (as
                                                                                                                                                                                        permitted
                                                                                                                                                                                        by
                                                                                                                                                                                        applicable
                                                                                                                                                                                        law,
                                                                                                                                                                                        including
                                                                                                                                                                                        Florida
                                                                                                                                                                                        s
                                                                                                                                                                                        privacy
                                                                                                                                                                                        law)
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span className="statement-end-if-in-editor">
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                            }}
                                                                                                                                                                                        />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </li>
                                                                                                                                                                            </ul>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <strong>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="heading_2">
                                                                                                                                                                                            How
                                                                                                                                                                                            to
                                                                                                                                                                                            Exercise
                                                                                                                                                                                            Your
                                                                                                                                                                                            Rights
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </strong>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            To
                                                                                                                                                                                            exercise
                                                                                                                                                                                            these
                                                                                                                                                                                            rights,
                                                                                                                                                                                            you
                                                                                                                                                                                            can
                                                                                                                                                                                            contact
                                                                                                                                                                                            us{' '}
                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                            by
                                                                                                                                                                                            visiting{' '}
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(0, 58, 250)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span className="question">
                                                                                                                                                                                                    <a
                                                                                                                                                                                                        href="https://form.jotform.com/233013525423141"
                                                                                                                                                                                                        target="_blank"
                                                                                                                                                                                                        data-custom-class="link"
                                                                                                                                                                                                        rel="noreferrer"
                                                                                                                                                                                                    >
                                                                                                                                                                                                        https://form.jotform.com/233013525423141
                                                                                                                                                                                                    </a>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>

                                                                                                                                                                                            ,{' '}
                                                                                                                                                                                            <span className="else-block" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                                        by
                                                                                                                                                                                                        emailing
                                                                                                                                                                                                        us
                                                                                                                                                                                                        at{' '}
                                                                                                                                                                                                        <span className="question">
                                                                                                                                                                                                            info@passingpawsihe.com
                                                                                                                                                                                                        </span>

                                                                                                                                                                                                        ,{' '}
                                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                                        by
                                                                                                                                                                                                        calling
                                                                                                                                                                                                        toll-free
                                                                                                                                                                                                        at{' '}
                                                                                                                                                                                                        <span className="question">
                                                                                                                                                                                                            (636)
                                                                                                                                                                                                            755-4247
                                                                                                                                                                                                        </span>

                                                                                                                                                                                                        ,{' '}
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                                                        by
                                                                                                                                                                                                                        visiting{' '}
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color: 'rgb(0, 58, 250)',
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span className="question">
                                                                                                                                                                                                                                <a
                                                                                                                                                                                                                                    href="https://form.jotform.com/233013525423141"
                                                                                                                                                                                                                                    target="_blank"
                                                                                                                                                                                                                                    data-custom-class="link"
                                                                                                                                                                                                                                    rel="noreferrer"
                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                    https://form.jotform.com/233013525423141
                                                                                                                                                                                                                                </a>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>

                                                                                                                                                                                                                        ,{' '}
                                                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                                                        <span className="block-component">
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                    or
                                                                                                                                                                                    by
                                                                                                                                                                                    referring
                                                                                                                                                                                    to
                                                                                                                                                                                    the
                                                                                                                                                                                    contact
                                                                                                                                                                                    details
                                                                                                                                                                                    at
                                                                                                                                                                                    the
                                                                                                                                                                                    bottom
                                                                                                                                                                                    of
                                                                                                                                                                                    this
                                                                                                                                                                                    document.
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        Under
                                                                                                                                                                                        certain
                                                                                                                                                                                        US
                                                                                                                                                                                        state
                                                                                                                                                                                        data
                                                                                                                                                                                        protection
                                                                                                                                                                                        laws,
                                                                                                                                                                                        you
                                                                                                                                                                                        can
                                                                                                                                                                                        designate
                                                                                                                                                                                        an{' '}
                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                        authorized
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                                                        agent
                                                                                                                                                                                        to
                                                                                                                                                                                        make
                                                                                                                                                                                        a
                                                                                                                                                                                        request
                                                                                                                                                                                        on
                                                                                                                                                                                        your
                                                                                                                                                                                        behalf.
                                                                                                                                                                                        We
                                                                                                                                                                                        may
                                                                                                                                                                                        deny
                                                                                                                                                                                        a
                                                                                                                                                                                        request
                                                                                                                                                                                        from
                                                                                                                                                                                        an{' '}
                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                        authorized
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                                                        agent
                                                                                                                                                                                        that
                                                                                                                                                                                        does
                                                                                                                                                                                        not
                                                                                                                                                                                        submit
                                                                                                                                                                                        proof
                                                                                                                                                                                        that
                                                                                                                                                                                        they
                                                                                                                                                                                        have
                                                                                                                                                                                        been
                                                                                                                                                                                        validly{' '}
                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                        authorized
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                                                        to
                                                                                                                                                                                        act
                                                                                                                                                                                        on
                                                                                                                                                                                        your
                                                                                                                                                                                        behalf
                                                                                                                                                                                        in
                                                                                                                                                                                        accordance
                                                                                                                                                                                        with
                                                                                                                                                                                        applicable
                                                                                                                                                                                        laws.
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <br />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <strong>
                                                                                                                                                                                        <span data-custom-class="heading_2">
                                                                                                                                                                                            Request
                                                                                                                                                                                            Verification
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </strong>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        Upon
                                                                                                                                                                                        receiving
                                                                                                                                                                                        your
                                                                                                                                                                                        request,
                                                                                                                                                                                        we
                                                                                                                                                                                        will
                                                                                                                                                                                        need
                                                                                                                                                                                        to
                                                                                                                                                                                        verify
                                                                                                                                                                                        your
                                                                                                                                                                                        identity
                                                                                                                                                                                        to
                                                                                                                                                                                        determine
                                                                                                                                                                                        you
                                                                                                                                                                                        are
                                                                                                                                                                                        the
                                                                                                                                                                                        same
                                                                                                                                                                                        person
                                                                                                                                                                                        about
                                                                                                                                                                                        whom
                                                                                                                                                                                        we
                                                                                                                                                                                        have
                                                                                                                                                                                        the
                                                                                                                                                                                        information
                                                                                                                                                                                        in
                                                                                                                                                                                        our
                                                                                                                                                                                        system.
                                                                                                                                                                                        We
                                                                                                                                                                                        will
                                                                                                                                                                                        only
                                                                                                                                                                                        use
                                                                                                                                                                                        personal
                                                                                                                                                                                        information
                                                                                                                                                                                        provided
                                                                                                                                                                                        in
                                                                                                                                                                                        your
                                                                                                                                                                                        request
                                                                                                                                                                                        to
                                                                                                                                                                                        verify
                                                                                                                                                                                        your
                                                                                                                                                                                        identity
                                                                                                                                                                                        or
                                                                                                                                                                                        authority
                                                                                                                                                                                        to
                                                                                                                                                                                        make
                                                                                                                                                                                        the
                                                                                                                                                                                        request.
                                                                                                                                                                                        However,
                                                                                                                                                                                        if
                                                                                                                                                                                        we
                                                                                                                                                                                        cannot
                                                                                                                                                                                        verify
                                                                                                                                                                                        your
                                                                                                                                                                                        identity
                                                                                                                                                                                        from
                                                                                                                                                                                        the
                                                                                                                                                                                        information
                                                                                                                                                                                        already
                                                                                                                                                                                        maintained
                                                                                                                                                                                        by
                                                                                                                                                                                        us,
                                                                                                                                                                                        we
                                                                                                                                                                                        may
                                                                                                                                                                                        request
                                                                                                                                                                                        that
                                                                                                                                                                                        you
                                                                                                                                                                                        provide
                                                                                                                                                                                        additional
                                                                                                                                                                                        information
                                                                                                                                                                                        for
                                                                                                                                                                                        the
                                                                                                                                                                                        purposes
                                                                                                                                                                                        of
                                                                                                                                                                                        verifying
                                                                                                                                                                                        your
                                                                                                                                                                                        identity
                                                                                                                                                                                        and
                                                                                                                                                                                        for
                                                                                                                                                                                        security
                                                                                                                                                                                        or
                                                                                                                                                                                        fraud-prevention
                                                                                                                                                                                        purposes.
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        If
                                                                                                                                                                                        you
                                                                                                                                                                                        submit
                                                                                                                                                                                        the
                                                                                                                                                                                        request
                                                                                                                                                                                        through
                                                                                                                                                                                        an{' '}
                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                        authorized
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                                                        agent,
                                                                                                                                                                                        we
                                                                                                                                                                                        may
                                                                                                                                                                                        need
                                                                                                                                                                                        to
                                                                                                                                                                                        collect
                                                                                                                                                                                        additional
                                                                                                                                                                                        information
                                                                                                                                                                                        to
                                                                                                                                                                                        verify
                                                                                                                                                                                        your
                                                                                                                                                                                        identity
                                                                                                                                                                                        before
                                                                                                                                                                                        processing
                                                                                                                                                                                        your
                                                                                                                                                                                        request
                                                                                                                                                                                        and
                                                                                                                                                                                        the
                                                                                                                                                                                        agent
                                                                                                                                                                                        will
                                                                                                                                                                                        need
                                                                                                                                                                                        to
                                                                                                                                                                                        provide
                                                                                                                                                                                        a
                                                                                                                                                                                        written
                                                                                                                                                                                        and
                                                                                                                                                                                        signed
                                                                                                                                                                                        permission
                                                                                                                                                                                        from
                                                                                                                                                                                        you
                                                                                                                                                                                        to
                                                                                                                                                                                        submit
                                                                                                                                                                                        such
                                                                                                                                                                                        request
                                                                                                                                                                                        on
                                                                                                                                                                                        your
                                                                                                                                                                                        behalf.
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="heading_2">
                                                                                                                                                                                        <strong>
                                                                                                                                                                                            Appeals
                                                                                                                                                                                        </strong>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        Under
                                                                                                                                                                                        certain
                                                                                                                                                                                        US
                                                                                                                                                                                        state
                                                                                                                                                                                        data
                                                                                                                                                                                        protection
                                                                                                                                                                                        laws,
                                                                                                                                                                                        if
                                                                                                                                                                                        we
                                                                                                                                                                                        decline
                                                                                                                                                                                        to
                                                                                                                                                                                        take
                                                                                                                                                                                        action
                                                                                                                                                                                        regarding
                                                                                                                                                                                        your
                                                                                                                                                                                        request,
                                                                                                                                                                                        you
                                                                                                                                                                                        may
                                                                                                                                                                                        appeal
                                                                                                                                                                                        our
                                                                                                                                                                                        decision
                                                                                                                                                                                        by
                                                                                                                                                                                        emailing
                                                                                                                                                                                        us
                                                                                                                                                                                        at{' '}
                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                        <span className="question">
                                                                                                                                                                                            info@passingpawsihe.com
                                                                                                                                                                                        </span>
                                                                                                                                                                                        <span className="else-block" />

                                                                                                                                                                                        .
                                                                                                                                                                                        We
                                                                                                                                                                                        will
                                                                                                                                                                                        inform
                                                                                                                                                                                        you
                                                                                                                                                                                        in
                                                                                                                                                                                        writing
                                                                                                                                                                                        of
                                                                                                                                                                                        any
                                                                                                                                                                                        action
                                                                                                                                                                                        taken
                                                                                                                                                                                        or
                                                                                                                                                                                        not
                                                                                                                                                                                        taken
                                                                                                                                                                                        in
                                                                                                                                                                                        response
                                                                                                                                                                                        to
                                                                                                                                                                                        the
                                                                                                                                                                                        appeal,
                                                                                                                                                                                        including
                                                                                                                                                                                        a
                                                                                                                                                                                        written
                                                                                                                                                                                        explanation
                                                                                                                                                                                        of
                                                                                                                                                                                        the
                                                                                                                                                                                        reasons
                                                                                                                                                                                        for
                                                                                                                                                                                        the
                                                                                                                                                                                        decisions.
                                                                                                                                                                                        If
                                                                                                                                                                                        your
                                                                                                                                                                                        appeal
                                                                                                                                                                                        is
                                                                                                                                                                                        denied,
                                                                                                                                                                                        you
                                                                                                                                                                                        may
                                                                                                                                                                                        submit
                                                                                                                                                                                        a
                                                                                                                                                                                        complaint
                                                                                                                                                                                        to
                                                                                                                                                                                        your
                                                                                                                                                                                        state
                                                                                                                                                                                        attorney
                                                                                                                                                                                        general.
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                                                                                </span>
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <strong>
                                                                                                                                                                                        <span data-custom-class="heading_2">
                                                                                                                                                                                            California{' '}
                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                            "Shine
                                                                                                                                                                                            The
                                                                                                                                                                                            Light"
                                                                                                                                                                                            <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                                                            Law
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </strong>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        California
                                                                                                                                                                                        Civil
                                                                                                                                                                                        Code
                                                                                                                                                                                        Section
                                                                                                                                                                                        1798.83,
                                                                                                                                                                                        also
                                                                                                                                                                                        known
                                                                                                                                                                                        as
                                                                                                                                                                                        the{' '}
                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                        "Shine
                                                                                                                                                                                        The
                                                                                                                                                                                        Light"
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                                                        law,
                                                                                                                                                                                        permits
                                                                                                                                                                                        our
                                                                                                                                                                                        users
                                                                                                                                                                                        who
                                                                                                                                                                                        are
                                                                                                                                                                                        California
                                                                                                                                                                                        residents
                                                                                                                                                                                        to
                                                                                                                                                                                        request
                                                                                                                                                                                        and
                                                                                                                                                                                        obtain
                                                                                                                                                                                        from
                                                                                                                                                                                        us,
                                                                                                                                                                                        once
                                                                                                                                                                                        a
                                                                                                                                                                                        year
                                                                                                                                                                                        and
                                                                                                                                                                                        free
                                                                                                                                                                                        of
                                                                                                                                                                                        charge,
                                                                                                                                                                                        information
                                                                                                                                                                                        about
                                                                                                                                                                                        categories
                                                                                                                                                                                        of
                                                                                                                                                                                        personal
                                                                                                                                                                                        information
                                                                                                                                                                                        (if
                                                                                                                                                                                        any)
                                                                                                                                                                                        we
                                                                                                                                                                                        disclosed
                                                                                                                                                                                        to
                                                                                                                                                                                        third
                                                                                                                                                                                        parties
                                                                                                                                                                                        for
                                                                                                                                                                                        direct
                                                                                                                                                                                        marketing
                                                                                                                                                                                        purposes
                                                                                                                                                                                        and
                                                                                                                                                                                        the
                                                                                                                                                                                        names
                                                                                                                                                                                        and
                                                                                                                                                                                        addresses
                                                                                                                                                                                        of
                                                                                                                                                                                        all
                                                                                                                                                                                        third
                                                                                                                                                                                        parties
                                                                                                                                                                                        with
                                                                                                                                                                                        which
                                                                                                                                                                                        we
                                                                                                                                                                                        shared
                                                                                                                                                                                        personal
                                                                                                                                                                                        information
                                                                                                                                                                                        in
                                                                                                                                                                                        the
                                                                                                                                                                                        immediately
                                                                                                                                                                                        preceding
                                                                                                                                                                                        calendar
                                                                                                                                                                                        year.
                                                                                                                                                                                        If
                                                                                                                                                                                        you
                                                                                                                                                                                        are
                                                                                                                                                                                        a
                                                                                                                                                                                        California
                                                                                                                                                                                        resident
                                                                                                                                                                                        and
                                                                                                                                                                                        would
                                                                                                                                                                                        like
                                                                                                                                                                                        to
                                                                                                                                                                                        make
                                                                                                                                                                                        such
                                                                                                                                                                                        a
                                                                                                                                                                                        request,
                                                                                                                                                                                        please
                                                                                                                                                                                        submit
                                                                                                                                                                                        your
                                                                                                                                                                                        request
                                                                                                                                                                                        in
                                                                                                                                                                                        writing
                                                                                                                                                                                        to
                                                                                                                                                                                        us
                                                                                                                                                                                        by
                                                                                                                                                                                        using
                                                                                                                                                                                        the
                                                                                                                                                                                        contact
                                                                                                                                                                                        details
                                                                                                                                                                                        provided
                                                                                                                                                                                        in
                                                                                                                                                                                        the
                                                                                                                                                                                        section{' '}
                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                        "
                                                                                                                                                                                        <span className="statement-end-if-in-editor" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                    <a
                                                                                                                                                                                        data-custom-class="link"
                                                                                                                                                                                        href="#contact"
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color: 'rgb(0, 58, 250)',
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            HOW
                                                                                                                                                                                            CAN
                                                                                                                                                                                            YOU
                                                                                                                                                                                            CONTACT
                                                                                                                                                                                            US
                                                                                                                                                                                            ABOUT
                                                                                                                                                                                            THIS
                                                                                                                                                                                            NOTICE?
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </a>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        <span className="block-component" />

                                                                                                                                                                                        "
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span className="statement-end-if-in-editor">
                                                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="statement-end-if-in-editor">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span className="statement-end-if-in-editor">
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                                        >
                                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                                                        >
                                                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                                                                        <span className="statement-end-if-in-editor">
                                                                                                                                                                                                                                                                            <span className="statement-end-if-in-editor" />
                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                id="policyupdates"
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        color: 'rgb(127, 127, 127)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    id="control"
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color: 'rgb(0, 0, 0)',
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <strong>
                                                                                                                                                                                                        <span data-custom-class="heading_1">
                                                                                                                                                                                                            10.
                                                                                                                                                                                                            DO
                                                                                                                                                                                                            WE
                                                                                                                                                                                                            MAKE
                                                                                                                                                                                                            UPDATES
                                                                                                                                                                                                            TO
                                                                                                                                                                                                            THIS
                                                                                                                                                                                                            NOTICE?
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </strong>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <em>
                                                                                                                                                                                                <strong>
                                                                                                                                                                                                    In
                                                                                                                                                                                                    Short:{' '}
                                                                                                                                                                                                </strong>
                                                                                                                                                                                                Yes,
                                                                                                                                                                                                we
                                                                                                                                                                                                will
                                                                                                                                                                                                update
                                                                                                                                                                                                this
                                                                                                                                                                                                notice
                                                                                                                                                                                                as
                                                                                                                                                                                                necessary
                                                                                                                                                                                                to
                                                                                                                                                                                                stay
                                                                                                                                                                                                compliant
                                                                                                                                                                                                with
                                                                                                                                                                                                relevant
                                                                                                                                                                                                laws.
                                                                                                                                                                                            </em>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            We
                                                                                                                                                                                            may
                                                                                                                                                                                            update
                                                                                                                                                                                            this
                                                                                                                                                                                            privacy
                                                                                                                                                                                            notice
                                                                                                                                                                                            from
                                                                                                                                                                                            time
                                                                                                                                                                                            to
                                                                                                                                                                                            time.
                                                                                                                                                                                            The
                                                                                                                                                                                            updated
                                                                                                                                                                                            version
                                                                                                                                                                                            will
                                                                                                                                                                                            be
                                                                                                                                                                                            indicated
                                                                                                                                                                                            by
                                                                                                                                                                                            an
                                                                                                                                                                                            updated{' '}
                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                            "Revised"
                                                                                                                                                                                            <span className="statement-end-if-in-editor" />{' '}
                                                                                                                                                                                            date
                                                                                                                                                                                            at
                                                                                                                                                                                            the
                                                                                                                                                                                            top
                                                                                                                                                                                            of
                                                                                                                                                                                            this
                                                                                                                                                                                            privacy
                                                                                                                                                                                            notice.
                                                                                                                                                                                            If
                                                                                                                                                                                            we
                                                                                                                                                                                            make
                                                                                                                                                                                            material
                                                                                                                                                                                            changes
                                                                                                                                                                                            to
                                                                                                                                                                                            this
                                                                                                                                                                                            privacy
                                                                                                                                                                                            notice,
                                                                                                                                                                                            we
                                                                                                                                                                                            may
                                                                                                                                                                                            notify
                                                                                                                                                                                            you
                                                                                                                                                                                            either
                                                                                                                                                                                            by
                                                                                                                                                                                            prominently
                                                                                                                                                                                            posting
                                                                                                                                                                                            a
                                                                                                                                                                                            notice
                                                                                                                                                                                            of
                                                                                                                                                                                            such
                                                                                                                                                                                            changes
                                                                                                                                                                                            or
                                                                                                                                                                                            by
                                                                                                                                                                                            directly
                                                                                                                                                                                            sending
                                                                                                                                                                                            you
                                                                                                                                                                                            a
                                                                                                                                                                                            notification.
                                                                                                                                                                                            We
                                                                                                                                                                                            encourage
                                                                                                                                                                                            you
                                                                                                                                                                                            to
                                                                                                                                                                                            review
                                                                                                                                                                                            this
                                                                                                                                                                                            privacy
                                                                                                                                                                                            notice
                                                                                                                                                                                            frequently
                                                                                                                                                                                            to
                                                                                                                                                                                            be
                                                                                                                                                                                            informed
                                                                                                                                                                                            of
                                                                                                                                                                                            how
                                                                                                                                                                                            we
                                                                                                                                                                                            are
                                                                                                                                                                                            protecting
                                                                                                                                                                                            your
                                                                                                                                                                                            information.
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                id="contact"
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        color: 'rgb(127, 127, 127)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    id="control"
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color: 'rgb(0, 0, 0)',
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <strong>
                                                                                                                                                                                                        <span data-custom-class="heading_1">
                                                                                                                                                                                                            11.
                                                                                                                                                                                                            HOW
                                                                                                                                                                                                            CAN
                                                                                                                                                                                                            YOU
                                                                                                                                                                                                            CONTACT
                                                                                                                                                                                                            US
                                                                                                                                                                                                            ABOUT
                                                                                                                                                                                                            THIS
                                                                                                                                                                                                            NOTICE?
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </strong>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            If
                                                                                                                                                                                            you
                                                                                                                                                                                            have
                                                                                                                                                                                            questions
                                                                                                                                                                                            or
                                                                                                                                                                                            comments
                                                                                                                                                                                            about
                                                                                                                                                                                            this
                                                                                                                                                                                            notice,
                                                                                                                                                                                            you
                                                                                                                                                                                            may{' '}
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span className="block-component">
                                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                    email
                                                                                                                                                                                                    us
                                                                                                                                                                                                    at{' '}
                                                                                                                                                                                                    <span className="question">
                                                                                                                                                                                                        info@passingpawsihe.com
                                                                                                                                                                                                        or{' '}
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                    <span className="statement-end-if-in-editor">
                                                                                                                                                                                                        <span className="block-component" />
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        contact
                                                                                                                                                                                                        us
                                                                                                                                                                                                        by
                                                                                                                                                                                                        post
                                                                                                                                                                                                        at:
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span className="question">
                                                                                                                                                                                                                Passing
                                                                                                                                                                                                                Paws
                                                                                                                                                                                                                In-Home
                                                                                                                                                                                                                Pet
                                                                                                                                                                                                                Euthanasia,
                                                                                                                                                                                                                LLC
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        <span className="question">
                                                                                                                                                                                            191
                                                                                                                                                                                            Majestic
                                                                                                                                                                                            Lakes
                                                                                                                                                                                            Blvd
                                                                                                                                                                                        </span>
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        <span className="question">
                                                                                                                                                                                            Moscow
                                                                                                                                                                                            Mills
                                                                                                                                                                                        </span>
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                                                <span className="block-component" />

                                                                                                                                                                                                ,{' '}
                                                                                                                                                                                                <span className="question">
                                                                                                                                                                                                    MO
                                                                                                                                                                                                </span>
                                                                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                                                <span className="block-component" />{' '}
                                                                                                                                                                                                <span className="question">
                                                                                                                                                                                                    63362
                                                                                                                                                                                                </span>
                                                                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                                                <span className="block-component" />
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span className="question">
                                                                                                                                                                                        United
                                                                                                                                                                                        States
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span className="statement-end-if-in-editor" />
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span className="statement-end-if-in-editor" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span className="statement-end-if-in-editor">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span className="block-component">
                                                                                                                                                                                                                    <span className="block-component" />
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span className="block-component">
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                            }}
                                                                                                                                                                                        />
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <span className="statement-end-if-in-editor">
                                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                id="request"
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        color: 'rgb(127, 127, 127)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color: 'rgb(89, 89, 89)',
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color: 'rgb(89, 89, 89)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    id="control"
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color: 'rgb(0, 0, 0)',
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <strong>
                                                                                                                                                                                                        <span data-custom-class="heading_1">
                                                                                                                                                                                                            12.
                                                                                                                                                                                                            HOW
                                                                                                                                                                                                            CAN
                                                                                                                                                                                                            YOU
                                                                                                                                                                                                            REVIEW,
                                                                                                                                                                                                            UPDATE,
                                                                                                                                                                                                            OR
                                                                                                                                                                                                            DELETE
                                                                                                                                                                                                            THE
                                                                                                                                                                                                            DATA
                                                                                                                                                                                                            WE
                                                                                                                                                                                                            COLLECT
                                                                                                                                                                                                            FROM
                                                                                                                                                                                                            YOU?
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </strong>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <br />
                                                                                                                                                                            </div>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        '1.5',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                        color: 'rgb(89, 89, 89)',
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color: 'rgb(89, 89, 89)',
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                            You
                                                                                                                                                                                            have
                                                                                                                                                                                            the
                                                                                                                                                                                            right
                                                                                                                                                                                            to
                                                                                                                                                                                            request
                                                                                                                                                                                            access
                                                                                                                                                                                            to
                                                                                                                                                                                            the
                                                                                                                                                                                            personal
                                                                                                                                                                                            information
                                                                                                                                                                                            we
                                                                                                                                                                                            collect
                                                                                                                                                                                            from
                                                                                                                                                                                            you,
                                                                                                                                                                                            details
                                                                                                                                                                                            about
                                                                                                                                                                                            how
                                                                                                                                                                                            we
                                                                                                                                                                                            have
                                                                                                                                                                                            processed
                                                                                                                                                                                            it,
                                                                                                                                                                                            correct
                                                                                                                                                                                            inaccuracies,
                                                                                                                                                                                            or
                                                                                                                                                                                            delete
                                                                                                                                                                                            your
                                                                                                                                                                                            personal
                                                                                                                                                                                            information.
                                                                                                                                                                                            You
                                                                                                                                                                                            may
                                                                                                                                                                                            also
                                                                                                                                                                                            have
                                                                                                                                                                                            the
                                                                                                                                                                                            right
                                                                                                                                                                                            to{' '}
                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                            withdraw
                                                                                                                                                                                            your
                                                                                                                                                                                            consent
                                                                                                                                                                                            to
                                                                                                                                                                                            our
                                                                                                                                                                                            processing
                                                                                                                                                                                            of
                                                                                                                                                                                            your
                                                                                                                                                                                            personal
                                                                                                                                                                                            information.
                                                                                                                                                                                            These
                                                                                                                                                                                            rights
                                                                                                                                                                                            may
                                                                                                                                                                                            be
                                                                                                                                                                                            limited
                                                                                                                                                                                            in
                                                                                                                                                                                            some
                                                                                                                                                                                            circumstances
                                                                                                                                                                                            by
                                                                                                                                                                                            applicable
                                                                                                                                                                                            law.
                                                                                                                                                                                            To
                                                                                                                                                                                            request
                                                                                                                                                                                            to
                                                                                                                                                                                            review,
                                                                                                                                                                                            update,
                                                                                                                                                                                            or
                                                                                                                                                                                            delete
                                                                                                                                                                                            your
                                                                                                                                                                                            personal
                                                                                                                                                                                            information,
                                                                                                                                                                                            please{' '}
                                                                                                                                                                                            <span className="block-component" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            visit:{' '}
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: 'rgb(0, 58, 250)',
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span className="question">
                                                                                                                                                                                                    <a
                                                                                                                                                                                                        href="https://form.jotform.com/233013525423141"
                                                                                                                                                                                                        target="_blank"
                                                                                                                                                                                                        data-custom-class="link"
                                                                                                                                                                                                        rel="noreferrer"
                                                                                                                                                                                                    >
                                                                                                                                                                                                        https://form.jotform.com/233013525423141
                                                                                                                                                                                                    </a>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                            <span className="else-block" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        .
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <style
                                                                                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                                                                                    __html: '\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Poppins ;\n      }\n    ',
                                                                                                                                                                                }}
                                                                                                                                                                            />
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
        </main>
    );
};
