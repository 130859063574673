import { Box, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Skeleton, Typography, useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import { BulletIcon } from 'components/BulletIcon';
import { HelmetHeader } from 'components/HelmetHeader';
import { PageBanner } from 'components/PageBanner';
import { useState } from 'react';
import { ResourceId, Resources } from 'shared/resources';
import Banner from '../assets/How-Do-I-Know-Its-Time-Banner.webp';
import dogGlasses from '../assets/dog-glasses-360x240.webp';
import TimePic from '../assets/how-do-i-know-its-time-360x240.webp';
import { navItems, Paths } from './Routes';

export const Time = () => {
    const [catLoaded, setCatLoadingState] = useState(false);
    const [dogLoaded, setDogLoadingState] = useState(false);
    const theme = useTheme();
    const secondaryTextColor = theme.palette.text.secondary;

    const navItem = navItems.find((ni) => ni.path === Paths.time);

    const title = navItem?.title ?? '';

    return (
        <main id="main">
            <HelmetHeader
                title={title}
                description="Advice for determining if Euthanasia is the correct choice based on your pet's quality of life (QOL)."
                relPath={navItem?.path}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'flex-start', md: 'center' } }}>
                <PageBanner
                    title={title}
                    bannerURL={Banner}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        maxWidth: 1200,
                        width: '100%',
                        marginTop: 2,
                    }}
                >
                    <Card sx={{ marginLeft: 4, marginRight: 4 }}>
                        <CardContent>
                            <Box
                                sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column' }, alignItems: { lg: 'flex-start', xs: 'center' } }}
                                style={{ marginBottom: 20 }}
                            >
                                <Box style={{ display: 'flex', marginRight: 20 }}>
                                    <img
                                        style={{ borderRadius: 8, maxWidth: '390px', maxHeight: '260px', height: '60vw', width: '90vw' }}
                                        alt={'Cat laying in a soft bed looking at the viewer.'}
                                        src={TimePic}
                                        onLoad={() => setCatLoadingState(true)}
                                    />
                                    {!catLoaded && (
                                        <Skeleton
                                            style={{ borderRadius: 8, maxWidth: '390px', maxHeight: '260px', height: '60vw', width: '90vw' }}
                                            variant="rectangular"
                                            animation="wave"
                                        />
                                    )}
                                </Box>

                                <Box sx={{ width: 'auto' }}>
                                    <Typography marginBottom={2}>
                                        As pet owners, the most important decision that will ever be made in our pet’s life is deciding when it is
                                        time to say goodbye. Very rarely does a pet pass away on their own in their sleep as seen in movies, often
                                        leaving us to make that gut-wrenching decision.
                                    </Typography>
                                    <Typography>
                                        As age or illness changes our pet’s ability to function in their normal capacity, there will come a time when
                                        we must decide what is the right thing to do for our pet. If we wait too long, we may accidentally cause
                                        unintended suffering and a less than peaceful death. Of course, there is no reason to be afraid of a natural
                                        death if that is what one prefers, as it is what Mother Nature had intended after all. We find many families
                                        choose to euthanize a pet sooner rather than later after experiencing a natural death as the end stages of
                                        death can be difficult for them to watch.
                                    </Typography>
                                </Box>
                            </Box>

                            <Typography>We make this decision by evaluating several important aspects of our pet’s daily life such as:</Typography>
                            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography>Does my pet seem to be in pain?</Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography>Is my pet eating and drinking like normal?</Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography>Is my pet still able to do their normal/favorite activities?</Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography>Can my pet still urinate and defecate? Is my pet incontinent?</Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography>Is my pet having difficulty breathing?</Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography>Has my pet’s behavior changed? Are they confused/aggressive?</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                                <Box>
                                    <img
                                        style={{
                                            borderRadius: 8,
                                            maxHeight: '240px',
                                            maxWidth: '360px',
                                            height: '40vw',
                                            width: '60vw',
                                        }}
                                        alt={'Sleeping dog with oversized glasses.'}
                                        src={dogGlasses}
                                        onLoad={() => setDogLoadingState(true)}
                                    />
                                    {!dogLoaded && (
                                        <Skeleton
                                            style={{ borderRadius: 8, maxHeight: '240px', maxWidth: '360px', height: '40vw', width: '60vw' }}
                                            variant="rectangular"
                                            animation="wave"
                                        />
                                    )}
                                </Box>
                            </Box>

                            <Typography>
                                Going through these questions and the Quality of Life (QOL) questionnaire below as honestly as possible can help guide
                                your decision. If it doesn’t seem to be black and white, it is ok to track your pet’s QOL score and see what their
                                trend is over time. You may also schedule a{' '}
                                <Link
                                    color={secondaryTextColor}
                                    href={`/${Paths.services}#tele-advice`}
                                >
                                    Tele-advice
                                </Link>{' '}
                                call with Dr. Shantelle if you need additional guidance.
                            </Typography>
                            <List>
                                {Resources.filter((r) =>
                                    [ResourceId.QualityOfLife, ResourceId.CaninePainScale, ResourceId.FelineGrimaceScale].includes(r.id),
                                ).map((r) => (
                                    <ListItem key={r.id}>
                                        <ListItemIcon>
                                            <BulletIcon theme={theme} />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Link
                                                color={secondaryTextColor}
                                                href={r.url}
                                            >
                                                {r.name}
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </main>
    );
};
