import { CardMedia } from '@mui/material';

export interface CarouselItemProps {
    image: string;
    title: string;
}

export function CarouselImage(props: CarouselItemProps) {
    return (
        <CardMedia
            sx={{ width: 320, minHeight: 320 }}
            image={props.image}
            title={props.title}
        />
    );
}
