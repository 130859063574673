import { SxProps, Theme as SystemTheme } from '@mui/material';

export const containerSxProps: SxProps<SystemTheme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'flex-start', md: 'center' },
};

export const sectionSxProps: SxProps<SystemTheme> = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 6,
};

export const subSectionSxProps: SxProps<SystemTheme> = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 2,
};

export const titleBoxSxPropsSansTheme: SxProps<SystemTheme> = {
    marginTop: -4,
    marginLeft: -4,
    marginRight: -4,
    paddingTop: 2,
    paddingLeft: 4,
    paddingRight: 4,
    marginBottom: 2,
};
