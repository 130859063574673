import { baseUrl } from 'pages/Routes';
import { Helmet } from 'react-helmet';

export type OgProps = {
    type?: string;
    title?: string;
    description?: string;
    imageName: string;
    alt: string;
};

export type HelmetProps = {
    title: string;
    description: string;
    relPath?: string;
    og: OgProps;
};

export const HelmetHeader = (props: HelmetProps) => {
    const { title, description, relPath, og } = props;
    const absolutePath = baseUrl + (relPath ?? '');
    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="title"
                content={title}
            />
            <meta
                name="description"
                content={description}
            />
            <link
                rel="canonical"
                href={absolutePath}
            />

            <meta
                property="og:url"
                content={absolutePath}
            />
            <meta
                property="og:locale"
                content="en_US"
            />
            <meta
                property="og:type"
                content={og.type ?? 'website'}
            />
            <meta
                property="og:title"
                content={og.title ?? title}
            />
            <meta
                property="og:description"
                content={og.description ?? description}
            />
            <meta
                property="og:image"
                content={baseUrl + '/images/' + og.imageName}
            />
            <meta
                property="og:image:alt"
                content={og.alt}
            />
        </Helmet>
    );
};
