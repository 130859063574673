import PetsIcon from '@mui/icons-material/Pets';
import { Theme } from '@mui/material';

interface BulletIconProps {
    theme: Theme;
}

export const BulletIcon = (props: BulletIconProps) => {
    return (
        <PetsIcon
            fontSize="small"
            sx={{ color: props.theme.palette.secondary.main }}
        />
    );
};
