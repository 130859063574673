import CopyrightIcon from '@mui/icons-material/Copyright';
import { AppBar, Box, Link, Toolbar, Typography, useTheme } from '@mui/material';
import { websiteName } from 'App';
import { navItems, Paths } from 'pages/Routes';

export const Footer = () => {
    const theme = useTheme();
    return (
        <AppBar
            position="relative"
            sx={{ display: 'flex', marginTop: 'auto' }}
        >
            {
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        justifyItems: 'center',
                        textAlign: 'center',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            justifyItems: 'center',
                            textAlign: 'center',
                            flexDirection: 'row',
                        }}
                    >
                        <CopyrightIcon
                            style={{ marginRight: 2 }}
                            fontSize="small"
                        />
                        <Typography sx={{ fontSize: '0.75em' }}>2024 {websiteName}</Typography>
                    </Box>

                    <Link
                        color={theme.palette.text.secondary}
                        href={navItems.find((item) => item.path === Paths.privacy)?.path}
                        sx={{ marginLeft: 1, fontSize: '0.85em' }}
                    >
                        Privacy Policy
                    </Link>
                    <Link
                        color={theme.palette.text.secondary}
                        href={navItems.find((item) => item.path === Paths.terms)?.path}
                        sx={{ marginLeft: 1, fontSize: '0.85em' }}
                    >
                        Terms And Conditions
                    </Link>
                </Toolbar>
            }
        </AppBar>
    );
};
